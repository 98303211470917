import axios from "axios";

// Utility function to fetch news from the /api/news endpoint, fetching all pages if limit is not provided
export const fetchNewsFromApi = async (limit = 10, page = 1) => {
  try {
    let allItems = [];
    let currentPage = page;
    let totalPages = 1;

    // Keep fetching until all pages are retrieved
    do {
      const response = await axios.get(
        `https://flowconnectbackend-864654c6a577.herokuapp.com/api/news?limit=${limit}&page=${currentPage}`
      );
      const data = response.data;

      if (data && data.items) {
        allItems = [...allItems, ...data.items];
        totalPages = data.totalPages;
        currentPage++;
      } else {
        break;
      }
    } while (currentPage <= totalPages);

    return {
      items: allItems,
      totalItems: allItems.length,
    };
  } catch (error) {
    console.error("Error fetching news from API:", error.message);
    return {
      items: [],
      totalItems: 0,
    };
  }
};

// Utility function to shorten text (if needed)
export const shortenText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

// Utility function to capitalize the first letter of a string
export const capitalizeFirstLetter = (text) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// Utility function to fetch the 5 most recent news items
export const fetchLatestNews = async () => {
  try {
    const response = await axios.get(
      `https://flowconnectbackend-864654c6a577.herokuapp.com/api/news?limit=5`
    );
    const data = response.data;

    // Check if the API returned data successfully
    if (data && data.items && data.items.length > 0) {
      return data.items; // Return the 5 most recent news items
    } else {
      throw new Error("No news items available");
    }
  } catch (error) {
    console.error("Error fetching the latest news from API:", error.message);
    return [];
  }
};

// Utility function to map the RSS source URL and name to its corresponding label
export const getSourceLabel = (source, url) => {
  // Mapping for known URL fragments to source labels for DapperLabs sources
  const urlToSourceMap = {
    "meetdapper.com": "DapperLabs",
    "onflow.org": "OnFlow",
    "flow.com": "Flow",
    "nbatopshot.com": "TopShot",
    "nflallday.com": "NFL AllDay",
    "ufcstrike.com": "UFC Strike",
    "laligagolazos.com": "LaLiga Golazos",
  };

  // Mapping for known source names (other than DapperLabs)
  const sourceNameMap = {
    "Collectin&Connectin": "Collectin & Connectin",
    "Diamond Dashboards": "Diamond Dashboards",
    "Own The Moment": "Own The Moment",
    Flowverse: "Flowverse",
    FanCraze: "FanCraze",
    TandyTime: "TandyTime",
    PlayMFL: "PlayMFL",
    FindLabs: "FindLabs",
    Cryptoys: "Cryptoys",
    "NLL Council": "NLL Council",
  };

  // Check if the source is DapperLabs and inspect the URL for the correct mapping
  if (source === "DapperLabs") {
    // Check the URL for known fragments to map it to a more specific label
    for (const [urlFragment, label] of Object.entries(urlToSourceMap)) {
      if (url.includes(urlFragment)) {
        return label;
      }
    }
    return "DapperLabs"; // Default to DapperLabs if URL doesn't match
  }

  // For non-DapperLabs sources, map the source name using sourceNameMap
  return sourceNameMap[source] || source || "Unknown Source"; // Default to source name or "Unknown Source"
};
