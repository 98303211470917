export const loadEditionImage = (id, project) => {
  if (!project) {
    throw new Error(
      "Project type is required. Specify either 'topshot' or 'pinnacle'."
    );
  }

  // Check if the project is "topshot" or "pinnacle" and assign paths accordingly
  let baseUrl;
  let placeholder;

  if (project === "topshot") {
    baseUrl = "https://storage.googleapis.com/flowconnect/topshot/nfts";
    placeholder = `${baseUrl}/placeholder.png`;
  } else if (project === "pinnacle") {
    baseUrl = "https://storage.googleapis.com/flowconnect/pinnacle/pins";
    placeholder = `${baseUrl}/placeholder.png`;
  } else {
    throw new Error(`Unknown project type: ${project}`);
  }

  // Construct the image URL using the editionID
  const imageUrl = `${baseUrl}/${id}.png`;

  // Preload the image to check if it exists
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl);
    img.onerror = () => resolve(placeholder); // Use placeholder if image doesn't exist
  });
};

// Optimized utility function to load set image with caching logic for multiple projects
export const loadSetImage = async (setID, project = "pinnacle") => {
  // Determine the base URL and placeholder based on the project
  let baseUrl, placeholderUrl, fileExtension;

  // Check project type and set the file extension accordingly
  if (project === "topshot") {
    baseUrl = `https://storage.googleapis.com/flowconnect/topshot/sets_small`;
    placeholderUrl = `${baseUrl}/placeholder.png`; // TopShot uses .jpg
    fileExtension = "jpg"; // Use .jpg for TopShot
  } else if (project === "pinnacle") {
    baseUrl = `https://storage.googleapis.com/flowconnect/pinnacle/sets`;
    placeholderUrl = `${baseUrl}/placeholder.png`; // Pinnacle uses .png
    fileExtension = "png"; // Use .png for Pinnacle
  } else {
    throw new Error(`Unknown project: ${project}`);
  }

  // Construct the image URL with the appropriate file extension
  const imageUrl = `${baseUrl}/${setID}.${fileExtension}`;

  // Preload the image and return the image URL or placeholder if it doesn't exist
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl); // Image found
    img.onerror = () => resolve(placeholderUrl); // Fallback to placeholder if image not found
  });
};

// Mapping of TopShot set IDs to their respective tiers
const topshotSetTiers = {
  1: "ultimate",
  2: "common",
  3: "ultimate",
  4: "legendary",
  5: "rare",
  6: "rare",
  7: "rare",
  8: "legendary",
  9: "rare",
  10: "rare",
  11: "rare",
  12: "legendary",
  13: "rare",
  14: "common",
  15: "rare",
  16: "rare",
  17: "rare",
  18: "rare",
  19: "rare",
  20: "legendary",
  21: "rare",
  22: "common",
  23: "legendary",
  24: "rare",
  25: "rare",
  26: "common",
  27: "ultimate",
  28: "legendary",
  29: "rare",
  30: "rare",
  31: "legendary",
  32: "common",
  33: "common",
  34: "common",
  35: "rare",
  36: "common",
  37: "rare",
  38: "rare",
  39: "common",
  40: "rare",
  41: "legendary",
  42: "ultimate",
  43: "fandom",
  44: "common",
  45: "fandom",
  46: "rare",
  47: "rare",
  48: "common",
  49: "fandom",
  50: "legendary",
  51: "common",
  52: "ultimate",
  53: "legendary",
  54: "rare",
  55: "rare",
  56: "fandom",
  57: "legendary",
  58: "common",
  59: "common",
  60: "common",
  61: "legendary",
  62: "fandom",
  63: "rare",
  64: "rare",
  65: "common",
  66: "fandom",
  67: "common",
  68: "rare",
  69: "legendary",
  70: "fandom",
  71: "rare",
  72: "common",
  73: "common",
  74: "legendary/rare",
  75: "rare",
  76: "fandom",
  77: "fandom",
  78: "fandom",
  79: "rare",
  80: "fandom",
  81: "rare",
  82: "legendary",
  83: "rare",
  84: "common",
  85: "rare",
  86: "fandom",
  87: "fandom",
  88: "fandom",
  89: "rare",
  90: "common",
  91: "common",
  92: "common",
  93: "rare",
  94: "rare",
  95: "rare",
  96: "legendary",
  97: "rare",
  98: "common",
  99: "rare",
  100: "legendary/rare",
  101: "fandom",
  102: "rare",
  103: "legendary",
  104: "common",
  105: "common",
  106: "legendary",
  107: "fandom",
  108: "common",
  109: "legendary/rare",
  110: "legendary",
  111: "common",
  112: "rare",
  113: "common",
  114: "legendary/rare",
  115: "rare",
  116: "legendary",
  117: "legendary/rare",
  118: "legendary",
  119: "common",
  120: "rare",
  121: "legendary",
  122: "legendary",
  123: "legendary",
  124: "common",
  125: "common",
  126: "common",
  127: "rare",
  128: "rare",
  129: "common",
  130: "rare",
  131: "legendary",
  132: "common",
  133: "common",
  134: "rare",
  135: "rare",
  136: "rare",
  137: "common",
  138: "fandom",
  139: "legendary",
  140: "ultimate",
  141: "fandom/rare",
  142: "rare",
  143: "fandom",
  144: "common",
  145: "common",
  146: "rare",
  147: "legendary",
  148: "rare",
  149: "rare",
  150: "rare",
  151: "ultimate",
  152: "rare",
  153: "legendary",
  154: "legendary",
  155: "common",
  156: "common",
  157: "rare",
  158: "common",
  159: "rare",
  160: "legendary",
  161: "legendary",
  162: "legendary",
};

const mixedTierSets = {
  74: {
    2648: "legendary",
    2649: "legendary",
    2837: "legendary",
    2650: "rare",
    2651: "rare",
  },
  100: {
    3345: "legendary",
    3919: "legendary",
    5304: "legendary",
    4163: "rare",
    5301: "rare",
  },
  109: {
    3938: "legendary",
    5299: "legendary",
    4162: "rare",
    5300: "rare",
  },
  114: {
    3938: "legendary",
    5299: "legendary",
    4162: "rare",
    5300: "rare",
  },
  117: {
    4126: "legendary",
    4128: "legendary",
    5305: "legendary",
    4124: "rare",
    4125: "rare",
    4127: "rare",
    5306: "rare",
  },
  141: {
    5149: "fandom",
    5150: "fandom",
    5151: "fandom",
    5152: "fandom",
    5153: "fandom",
    5177: "rare",
    5178: "rare",
    5179: "rare",
  },
};

// Get the tier of the set based on its ID
export const getTopShotSetTier = (setId) => {
  return topshotSetTiers[setId] || "common"; // Default to "common" if the set ID is not found
};

// Function to get the tier of an edition based on setId and playId
export const getEditionTier = (setId, playId) => {
  // Check if the set is a mixed-tier set and has the playId
  if (mixedTierSets[setId] && mixedTierSets[setId][playId]) {
    return mixedTierSets[setId][playId]; // Return the tier for the specific playId in the mixed set
  }

  // If not in mixed-tier sets, return the general set tier
  return topshotSetTiers[setId] || "common"; // Default to "common" if no specific tier is found
};

// Combined utility function for getting background color based on project and either edition type or tier
export const getSetBackground = (project, editionType) => {
  if (project === "TopShot") {
    // Handle TopShot tiers and specific mixed-tier cases
    switch (editionType) {
      case "ultimate":
        return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave";
      case "legendary":
        return "bg-orange-500";
      case "rare":
        return "bg-blue-500";
      case "fandom":
        return "bg-lime-500";
      case "common":
        return "bg-gray-500";
      case "fandom/rare": // Mixed tier: fandom and rare
        return "bg-gradient-to-r from-lime-500 to-blue-500";
      case "legendary/rare": // Mixed tier: legendary and rare
        return "bg-gradient-to-r from-orange-500 to-blue-500";
      default:
        return "bg-green-200"; // Default fallback if the tier is not found
    }
  } else if (project === "Pinnacle") {
    // Handle Pinnacle tiers or edition types
    if (typeof editionType === "number") {
      // Edition types based on numeric tiers
      switch (editionType) {
        case 1:
          return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave"; // Genesis Edition
        case 2:
          return "bg-blue-300"; // Starter Edition
        case 3:
          return "bg-purple-800"; // Open Edition
        case 4:
          return "bg-blue-300"; // Limited Edition
        case 5:
          return "bg-indigo-400"; // Limited Event Edition
        case 7:
          return "bg-cyan-500"; // Open Event Edition
        default:
          return "bg-gray-100"; // Default fallback for unknown edition types
      }
    } else if (typeof editionType === "string") {
      // Edition types based on string labels
      switch (editionType) {
        case "Genesis Edition":
          return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave";
        case "Starter Edition":
          return "bg-indigo-400";
        case "Open Edition":
          return "bg-blue-300";
        case "Limited Edition":
          return "bg-purple-800";
        case "Limited Event Edition":
          return "bg-cyan-500";
        case "Open Event Edition":
          return "bg-cyan-300";
        default:
          return "bg-gray-100"; // Fallback for unknown edition types
      }
    }
  }

  // Default fallback if neither "TopShot" nor "Pinnacle"
  return "bg-green-200"; // Light green for sets without a specific tier
};

export const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

export const parseUnicode = (text) => {
  return text.replace(/\\u\{([0-9A-Fa-f]+)\}/g, (_, code) =>
    String.fromCharCode(parseInt(code, 16))
  );
};

// GenericHelper.js

export const getLogoUrl = (name) => {
  // List of known NBA team names
  const nbaTeams = [
    "Hawks",
    "Celtics",
    "Nets",
    "Hornets",
    "Bulls",
    "Cavaliers",
    "Mavericks",
    "Nuggets",
    "Pistons",
    "Warriors",
    "Rockets",
    "Pacers",
    "Clippers",
    "Lakers",
    "Grizzlies",
    "Heat",
    "Bucks",
    "Timberwolves",
    "Pelicans",
    "Knicks",
    "Thunder",
    "Magic",
    "76ers",
    "Suns",
    "Blazers",
    "Kings",
    "Spurs",
    "Raptors",
    "Jazz",
    "Wizards",
  ];

  // List of known NFL team names
  const nflTeams = [
    "49ers",
    "Bears",
    "Bengals",
    "Bills",
    "Broncos",
    "Browns",
    "Buccaneers",
    "Cardinals",
    "Chargers",
    "Chiefs",
    "Colts",
    "Commanders",
    "Cowboys",
    "Dolphins",
    "Eagles",
    "Falcons",
    "Giants",
    "Jaguars",
    "Jets",
    "Lions",
    "Packers",
    "Panthers",
    "Patriots",
    "Raiders",
    "Rams",
    "Ravens",
    "Saints",
    "Seahawks",
    "Steelers",
    "Texans",
    "Titans",
    "Vikings",
  ];

  // List of known calendar sources and their corresponding logo names
  const calendarSources = {
    "TopShot Active Challenges": "TopShotIcon",
    "NBA Games": "NBAIcon",
    "NFL Games": "NFLIcon",
    $BETA: "TopShotBeta",
    "Flow Events": "Flow",
    "Pet Jokic's Horses": "PetJokicsHorses",
  };

  // Check if the name matches a calendar source
  if (calendarSources[name]) {
    let fileExtension = "png"; // Default to .png

    // Use .svg for Flow, and ensure NFLIcon uses .png
    if (calendarSources[name] === "Flow") {
      fileExtension = "svg";
    } else if (calendarSources[name] === "NFLIcon") {
      fileExtension = "png";
    }

    return `https://storage.googleapis.com/flowconnect/logos/${calendarSources[name]}.${fileExtension}`;
  }

  // Create a regex pattern that matches any of the team names
  const regex = new RegExp(
    `\\b(${[...nbaTeams, ...nflTeams].join("|")})\\b`,
    "i"
  );

  // Find the matching team name
  const match = name.match(regex);
  if (!match) {
    // Return a default or error URL if no team or source is matched
    return `https://storage.googleapis.com/flowconnect/logos/FlowConnect.png`;
  }

  const formattedName = match[0].replace(/\s+/g, "");

  // Determine the file extension based on the league
  const fileExtension = nbaTeams.includes(formattedName) ? "png" : "svg";

  // Construct the URL for the logo
  return `https://storage.googleapis.com/flowconnect/logos/${formattedName}.${fileExtension}`;
};
