import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import fcl from "../config";
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";
import AllDayCommunities from "./AllDayCommunities"; // Placeholder component
import AllDayGaming from "./AllDayGaming";
import AllDayAnalytics from "./AllDayAnalytics";

const AllDay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  // Adjusted activeTab calculation
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const activeTab = pathSegments[1] || "analytics"; // Default to 'analytics' tab

  useEffect(() => {
    fcl.currentUser().subscribe((user) => {
      setUser(user?.addr ? user : null);
    });
  }, []);

  const handleTabChange = (tab) => {
    navigate(`/allday/${tab}`);
  };

  return (
    <div className="p-2">
      {/* Banner with logo and social links */}
      <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-gray-500 via-gray-300 to-gray-500 p-6 rounded-lg shadow-lg">
        <a
          href="https://nflallday.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
        >
          <img
            src="https://storage.googleapis.com/flowconnect/logos/AllDay.svg"
            alt="NFL AllDay Logo"
            className="h-20"
          />
        </a>
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
          <a
            href="https://www.instagram.com/nflallday/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaInstagram className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Instagram</span>
          </a>
          <a
            href="https://x.com/nflallday"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaTwitter className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">X</span>
          </a>
          <a
            href="https://discord.gg/nflallday"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaDiscord className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Discord</span>
          </a>
          <a
            href="https://www.reddit.com/r/nflallday/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaReddit className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Reddit</span>
          </a>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex space-x-4 mt-4 mb-8 border-b-2 pb-2">
        <button
          onClick={() => handleTabChange("analytics")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "analytics"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Analytics
        </button>

        <button
          onClick={() => handleTabChange("gaming")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "gaming"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Gaming
        </button>

        <button
          onClick={() => handleTabChange("communities")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "communities"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Communities
        </button>
      </div>

      {/* Content Based on Active Tab */}
      {activeTab === "analytics" && <AllDayAnalytics />}

      {activeTab === "gaming" && <AllDayGaming />}

      {activeTab === "communities" && <AllDayCommunities />}
    </div>
  );
};

export default AllDay;
