import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format, subMonths, subYears } from "date-fns"; // To format and manipulate dates

// Register necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const FlowLineChart = () => {
  const [chartData, setChartData] = useState({});
  const [timeRange, setTimeRange] = useState("Last Year"); // Default to 'All time'
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://flipsidecrypto.xyz/api/v1/queries/95c3c828-d26b-44bb-9f95-4263fd93b22e/data/latest"
      );
      const data = await response.json();

      // Process the data for each blockchain (now working with weekly data)
      const processData = (blockchain) =>
        data
          .filter((item) => item.BLOCKCHAIN === blockchain)
          .map((item) => ({
            date: new Date(item.ACTIVITY_WEEK),
            value: item.WEEKLY_ACTIVE_NFT_BUYERS,
          }));

      const ethereumData = processData("Ethereum");
      const polygonData = processData("Polygon");
      const flowData = processData("Flow");
      const baseData = processData("Base");
      const solanaData = processData("Solana");
      const aptosData = processData("Aptos");
      const arbitrumData = processData("Arbitrum");
      const avalancheData = processData("Avalanche");
      const bscData = processData("BSC");
      const nearData = processData("NEAR");
      const optimismData = processData("Optimism");

      // Create a set of all weeks for the X-axis
      const weeks = Array.from(
        new Set(
          data.map((item) => format(new Date(item.ACTIVITY_WEEK), "yyyy-MM-dd"))
        )
      );

      // Set initial chart data
      setChartData({
        weeks,
        ethereumData,
        polygonData,
        flowData,
        baseData,
        solanaData,
        aptosData,
        arbitrumData,
        avalancheData,
        bscData,
        nearData,
        optimismData,
      });
    };

    fetchData();
  }, []);

  // Handle time range filtering
  useEffect(() => {
    if (chartData.weeks) {
      const now = new Date();
      let filteredWeeks = chartData.weeks;

      switch (timeRange) {
        case "Last Year":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subYears(now, 1)
          );
          break;
        case "Last 6 Months":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subMonths(now, 6)
          );
          break;
        case "Last 3 Months":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subMonths(now, 3)
          );
          break;
        case "Last Month":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subMonths(now, 1)
          );
          break;
        default:
          filteredWeeks = chartData.weeks; // All time
      }

      // Update datasets based on the selected time range
      const updatedDatasets = [
        {
          label: "Ethereum",
          data: chartData.ethereumData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(54, 162, 235, 1)",
          pointRadius: 0,
        },
        {
          label: "Polygon",
          data: chartData.polygonData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(75, 192, 192, 1)",
          pointRadius: 0,
        },
        {
          label: "Flow",
          data: chartData.flowData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(255, 99, 132, 1)",
          pointRadius: 0,
        },
        {
          label: "Base",
          data: chartData.baseData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(255, 206, 86, 1)",
          pointRadius: 0,
        },
        {
          label: "Solana",
          data: chartData.solanaData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(153, 102, 255, 1)",
          pointRadius: 0,
        },
        {
          label: "Aptos",
          data: chartData.aptosData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(255, 159, 64, 1)",
          pointRadius: 0,
          hidden: true, // Aptos initially hidden
        },
        {
          label: "Arbitrum",
          data: chartData.arbitrumData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(54, 162, 235, 1)",
          pointRadius: 0,
          hidden: true, // Arbitrum initially hidden
        },
        {
          label: "Avalanche",
          data: chartData.avalancheData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(255, 99, 132, 1)",
          pointRadius: 0,
          hidden: true, // Avalanche initially hidden
        },
        {
          label: "BSC",
          data: chartData.bscData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(75, 192, 192, 1)",
          pointRadius: 0,
          hidden: true, // BSC initially hidden
        },
        {
          label: "NEAR",
          data: chartData.nearData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(255, 159, 64, 1)",
          pointRadius: 0,
          hidden: true, // NEAR initially hidden
        },
        {
          label: "Optimism",
          data: chartData.optimismData
            .filter((item) =>
              filteredWeeks.includes(format(item.date, "yyyy-MM-dd"))
            )
            .map((item) => item.value),
          borderColor: "rgba(255, 206, 86, 1)",
          pointRadius: 0,
          hidden: true, // Optimism initially hidden
        },
      ];

      setFilteredData({
        labels: filteredWeeks,
        datasets: updatedDatasets,
      });
    }
  }, [timeRange, chartData]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          Weekly Active NFT Buyers Across Blockchains
        </h3>

        {/* Dropdown to select time range */}
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mb-4 p-2 border rounded"
        >
          <option value="All">All time</option>
          <option value="Last Year">Last year</option>
          <option value="Last 6 Months">Last 6 months</option>
          <option value="Last 3 Months">Last 3 months</option>
          <option value="Last Month">Last month</option>
        </select>

        {filteredData.labels ? (
          <Line
            data={filteredData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                  text: "Weekly Active NFT Buyers by Blockchain",
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`; // Display label and value
                    },
                  },
                },
                datalabels: {
                  display: false, // Disable data point labels
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "week", // Show weekly data
                    displayFormats: {
                      week: "MMM yyyy",
                    },
                  },
                  ticks: {
                    maxTicksLimit: 10, // Limit the number of ticks on the x-axis
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Weekly Active NFT Buyers",
                  },
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default FlowLineChart;
