import React from "react";

const AllDayCommunities = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">NFL AllDay Communities</h2>
      <p className="text-gray-700 mb-4">
        Welcome to the NFL AllDay Communities section. Here, you'll find various
        groups and discussions where NFL AllDay collectors can connect, share
        insights, and engage with each other.
      </p>

      <div className="text-gray-500 italic">
        Community features and data are coming soon...
      </div>
    </div>
  );
};

export default AllDayCommunities;
