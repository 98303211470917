import React from "react";
import topshotImage from "../logos/TopShot.png";
import thirdparty from "../logos/evaluate.png";
import nflad from "../logos/nflad.png";
import flowty from "../logos/flowty.svg";
import flow from "../logos/flow.svg";
import dapperlabs from "../logos/dapperlabs.svg";
import cryptokitties from "../logos/cryptokitties.svg";
import PinnacleLogo from "../logos/Pinnacle.png";

const iconMap = {
  DapperLabs: dapperlabs,
  CryptoKitties: cryptokitties,
  Flow: flow,
  TopShot: topshotImage,
  NFLAD: nflad,
  ThirdParty: thirdparty,
  Pinnacle: PinnacleLogo,
};

const Filter = ({ iconTypes, selectedIconTypes, onFilterChange }) => {
  const handleFilterChange = (iconType) => {
    if (iconType === "DapperLabs") {
      const isSelected =
        selectedIconTypes.includes("DapperLabs") &&
        selectedIconTypes.includes("AxiomZen");
      if (isSelected) {
        onFilterChange("DapperLabs", false);
        onFilterChange("AxiomZen", false);
      } else {
        onFilterChange("DapperLabs", true);
        onFilterChange("AxiomZen", true);
      }
    } else {
      const isSelected = selectedIconTypes.includes(iconType);
      onFilterChange(iconType, !isSelected);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h4 className="text-center text-lg font-bold text-gray-800 mb-4">
        Select Projects
      </h4>
      <div className="flex flex-wrap justify-center mb-6 gap-4">
        {iconTypes
          .filter((iconType) => iconType !== "AxiomZen") // Exclude AxiomZen from rendering
          .map((iconType) => {
            const isSelected =
              iconType === "DapperLabs"
                ? selectedIconTypes.includes("DapperLabs") &&
                  selectedIconTypes.includes("AxiomZen")
                : selectedIconTypes.includes(iconType);

            const borderColor = isSelected
              ? "border-green-500"
              : "border-gray-300";
            const hoverEffect =
              "hover:scale-105 transform transition-transform duration-300";

            // Apply gray background for Pinnacle
            const backgroundColor =
              iconType === "Pinnacle" ? "bg-gray-300" : "bg-white";

            return (
              <div
                key={iconType}
                onClick={() => handleFilterChange(iconType)}
                className={`cursor-pointer ${backgroundColor} border-4 ${borderColor} p-2 rounded-full shadow-md ${hoverEffect}`}
                style={{ width: "80px", height: "80px" }}
              >
                <div className="relative w-full h-full flex items-center justify-center">
                  {iconType === "ThirdParty" ? (
                    <div className="relative flex items-center justify-center w-full h-full">
                      <img
                        src={flowty}
                        alt="Flowty"
                        className="w-6 h-6 object-contain absolute top-1 left-1 bg-black rounded-full p-1"
                      />
                      <img
                        src={thirdparty}
                        alt="Evaluate"
                        className="w-6 h-6 object-contain absolute bottom-1 right-1 rounded-full"
                      />
                    </div>
                  ) : (
                    <img
                      src={iconMap[iconType]}
                      alt={iconType}
                      className="w-full h-full object-contain rounded-full"
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Filter;
