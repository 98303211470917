import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format, subMonths, subYears } from "date-fns"; // For date manipulation

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const TopShotBuyersSellers = () => {
  const [chartData, setChartData] = useState({});
  const [timeRange, setTimeRange] = useState("Last Year"); // Default to 'Last Year'
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://flipsidecrypto.xyz/api/v1/queries/71c66c8e-bc82-495e-8645-28415e4d35e8/data/latest"
      );
      const data = await response.json();

      const activityWeeks = data.map((item) =>
        format(new Date(item.ACTIVITY_WEEK), "yyyy-MM-dd")
      );
      const uniqueBuyers = data.map((item) => item.WEEKLY_UNIQUE_BUYERS);
      const uniqueSellers = data.map((item) => item.WEEKLY_UNIQUE_SELLERS);

      setChartData({
        labels: activityWeeks,
        datasets: [
          {
            label: "Unique Buyers",
            data: uniqueBuyers,
            borderColor: "rgba(54, 162, 235, 1)",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: false,
            pointRadius: 0,
          },
          {
            label: "Unique Sellers",
            data: uniqueSellers,
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: false,
            pointRadius: 0,
          },
        ],
      });
    };

    fetchData();
  }, []);

  // Handle time range filtering
  useEffect(() => {
    if (chartData.labels) {
      const now = new Date();
      let filteredWeeks = chartData.labels;

      switch (timeRange) {
        case "Last Year":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subYears(now, 1)
          );
          break;
        case "Last 6 Months":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 6)
          );
          break;
        case "Last 3 Months":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 3)
          );
          break;
        case "Last Month":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 1)
          );
          break;
        default:
          filteredWeeks = chartData.labels; // All time
      }

      // Filter datasets based on the selected time range
      const updatedDatasets = chartData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.filter((_, idx) =>
          filteredWeeks.includes(chartData.labels[idx])
        ),
      }));

      setFilteredData({
        labels: filteredWeeks,
        datasets: updatedDatasets,
      });
    }
  }, [timeRange, chartData]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          TopShot: Unique Buyers and Sellers Over Time
        </h3>

        {/* Dropdown to select time range */}
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mb-4 p-2 border rounded"
        >
          <option value="All">All time</option>
          <option value="Last Year">Last year</option>
          <option value="Last 6 Months">Last 6 months</option>
          <option value="Last 3 Months">Last 3 months</option>
          <option value="Last Month">Last month</option>
        </select>

        {filteredData.labels ? (
          <Line
            data={filteredData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                    },
                  },
                },
                datalabels: {
                  display: false,
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "month",
                    displayFormats: {
                      month: "MMM yyyy",
                    },
                  },
                  ticks: {
                    maxTicksLimit: 12,
                  },
                },
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default TopShotBuyersSellers;
