import React from "react";

const AllDayGaming = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">NFL AllDay Gaming</h2>

      <p className="text-gray-700 mb-4">
        NFL AllDay offers a range of exciting challenges and opportunities for
        collectors to participate in. Use your Moments to compete for exclusive
        rewards and climb the leaderboards.
      </p>

      <h3 className="text-xl font-bold mt-8 mb-4">Challenges</h3>
      <p className="text-gray-700 mb-4">
        Challenges give NFL AllDay collectors a chance to use their Moments to
        earn exclusive rewards. Complete the challenge requirements and win
        unique prizes!
      </p>
      <a
        href="https://nflallday.com/challenges"
        target="_blank"
        rel="noopener noreferrer"
        className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
      >
        Explore NFL AllDay Challenges
      </a>

      <h3 className="text-xl font-bold mt-8 mb-4">Trade-Ins & Leaderboards</h3>
      <p className="text-gray-700 mb-4">
        Trade in your NFL AllDay Moments to compete for newly released Moments
        from your favorite players, or lock your collection for long-term
        rewards on the Player and Team Leaderboards.
      </p>
      <a
        href="https://nflallday.com/leaderboards"
        target="_blank"
        rel="noopener noreferrer"
        className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
      >
        View NFL AllDay Leaderboards
      </a>
    </div>
  );
};

export default AllDayGaming;
