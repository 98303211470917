import React, { useState } from "react";
import FlowLineChart from "./charts/FlowLineChart";
import FlowStackedAnalytics from "./charts/FlowStackedAnalytics";
import FlowTop5Projects from "./charts/FlowTop5Projects";
import PinnacleCirculation from "./charts/PinnacleCirculation";
import PinnacleCollectors from "./charts/PinnacleCollectors";
import TopShotOvertime from "./charts/TopShotOvertime";
import CryptoKittiesEggRarities from "./charts/CryptoKittiesEggRarities";
import CryptoKittiesEggSets from "./charts/CryptoKittiesEggSets";
import TopShotBuyersSellers from "./charts/TopShotBuyersSellers";
import TopShotSalesValue from "./charts/TopShotSalesValue";
import TopShotFloors from "./charts/TopShotFloors";

// AllDay charts
import AllDayOvertime from "./charts/AllDayOvertime";
import AllDayBuyersSellers from "./charts/AllDayBuyersSellers";
import AllDaySalesValue from "./charts/AllDaySalesValue";
import AllDayFloors from "./charts/AllDayFloors";

const Analytics = () => {
  const [filter, setFilter] = useState("All"); // Default to show all analytics

  // Handle filtering
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Render charts based on filter selection
  const renderCharts = () => {
    if (filter === "Flow") {
      return (
        <>
          <FlowLineChart />
          <FlowStackedAnalytics />
          <FlowTop5Projects />
        </>
      );
    }
    if (filter === "Pinnacle") {
      return (
        <>
          <PinnacleCirculation />
          <PinnacleCollectors />
        </>
      );
    }
    if (filter === "TopShot") {
      return (
        <>
          <TopShotOvertime />
          <TopShotBuyersSellers />
          <TopShotSalesValue />
          <TopShotFloors />
        </>
      );
    }
    if (filter === "CryptoKitties") {
      return (
        <>
          <CryptoKittiesEggRarities />
          <CryptoKittiesEggSets />
        </>
      );
    }
    if (filter === "AllDay") {
      return (
        <>
          <AllDayOvertime />
          <AllDayBuyersSellers />
          <AllDaySalesValue />
          <AllDayFloors />
        </>
      );
    }
    // Default: show all analytics
    return (
      <>
        <FlowLineChart />
        <FlowStackedAnalytics />
        <FlowTop5Projects />
        <PinnacleCirculation />
        <PinnacleCollectors />
        <TopShotOvertime />
        <TopShotBuyersSellers />
        <TopShotSalesValue />
        <TopShotFloors />
        <CryptoKittiesEggRarities />
        <CryptoKittiesEggSets />
        <AllDayOvertime />
        <AllDayBuyersSellers />
        <AllDaySalesValue />
        <AllDayFloors />
      </>
    );
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md min-h-screen">
      <h2 className="text-2xl font-bold mb-4">Analytics Dashboard</h2>

      {/* Filter selection */}
      <div className="mb-6">
        <label htmlFor="filter" className="block mb-2 font-semibold">
          Select Analytics Filter:
        </label>
        <select
          id="filter"
          value={filter}
          onChange={handleFilterChange}
          className="p-2 border rounded"
        >
          <option value="All">All</option>
          <option value="Flow">Flow</option>
          <option value="TopShot">TopShot</option>
          <option value="AllDay">AllDay</option>
          <option value="Pinnacle">Pinnacle</option>
          <option value="CryptoKitties">CryptoKitties</option>
        </select>
      </div>

      {/* Render charts based on selected filter */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {renderCharts()}
      </div>
    </div>
  );
};

export default Analytics;
