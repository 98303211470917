import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format } from "date-fns"; // To format dates if needed

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const TopShotOvertime = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://flipsidecrypto.xyz/api/v1/queries/9577f04a-86aa-4a57-a820-c644ed513df1/data/latest"
      );
      const data = await response.json();

      const eventWeeks = data.map((item) =>
        format(new Date(item.EVENT_WEEK), "yyyy-MM-dd")
      );

      const runningTotalMinted = data.map((item) => item.RUNNING_TOTAL_MINTED);
      const runningTotalBurned = data.map((item) => item.RUNNING_TOTAL_BURNED);
      const runningTotalCirculating = data.map(
        (item) => item.RUNNING_TOTAL_CIRCULATING
      );
      const runningTotalLocked = data.map((item) => item.RUNNING_TOTAL_LOCKED);
      const runningTotalInLockerRoom = data.map(
        (item) => item.RUNNING_TOTAL_IN_LOCKEROOM
      );

      setChartData({
        labels: eventWeeks,
        datasets: [
          {
            label: "Running Total Minted",
            data: runningTotalMinted,
            borderColor: "rgba(54, 162, 235, 1)",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: false, // Disable the fill
            pointRadius: 0,
          },
          {
            label: "Running Total Circulating",
            data: runningTotalCirculating,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: false, // Disable the fill
            pointRadius: 0,
          },
          {
            label: "Running Total Burned",
            data: runningTotalBurned,
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: false, // Disable the fill
            pointRadius: 0,
          },
          {
            label: "Running Total Locked",
            data: runningTotalLocked,
            borderColor: "rgba(255, 206, 86, 1)",
            backgroundColor: "rgba(255, 206, 86, 0.2)",
            fill: false, // Disable the fill
            pointRadius: 0,
          },
          {
            label: "Running Total in Locker Room",
            data: runningTotalInLockerRoom,
            borderColor: "rgba(153, 102, 255, 1)",
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            fill: false, // Disable the fill
            pointRadius: 0,
          },
        ],
      });
    };

    fetchData();
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          TopShot: Minted, Circulating, Burned, Locked, and Locker Room
        </h3>
        {chartData.labels ? (
          <Line
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false, // No title inside the chart, handled outside
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                    },
                  },
                },
                datalabels: {
                  display: false,
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "month",
                    displayFormats: {
                      month: "MMM yyyy", // Format as "Jan 2024"
                    },
                  },
                  ticks: {
                    maxTicksLimit: 12, // Limit the number of ticks displayed
                  },
                },
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default TopShotOvertime;
