import React from "react";
import { Link } from "react-router-dom";
import {
  getSetBackground,
  splitSetName,
  parseUnicode,
} from "./PinnacleHelpers";

const PinnacleSeries = ({
  series,
  selectedSeries,
  setData,
  cachedSetImages,
  completedSetIDs,
}) => {
  // Filtered sets based on selected series
  const filteredSets = selectedSeries
    ? setData.filter((set) => set.seriesID === selectedSeries)
    : [];

  return (
    <div>
      {/* Series Tabs */}
      <div className="flex space-x-2 mb-4 mt-2">
        {series.map((serie) => (
          <Link
            key={serie.id}
            to={`/pinnacle/series/${serie.id}`}
            className={`px-4 py-2 rounded-lg border transition-transform duration-300 hover:scale-105 ${
              selectedSeries === serie.id
                ? "bg-green-500 text-white"
                : "bg-white border-green-500 text-black"
            }`}
          >
            {`Series ${serie.name}`}
          </Link>
        ))}
      </div>

      {/* Sets View */}
      {selectedSeries && filteredSets.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {filteredSets.map((set) => {
            const { studio, setName } = splitSetName(parseUnicode(set.name));
            const isCompleted = completedSetIDs.includes(set.id);
            const setImage =
              cachedSetImages[set.id] ||
              "https://storage.googleapis.com/flowconnect/pinnacle/sets/placeholder.png";

            return (
              <Link
                to={`/pinnacle/sets/${set.id}`}
                key={set.id}
                className="no-underline"
              >
                <div
                  className={`${getSetBackground(
                    set.editionType
                  )} text-slate-200 p-4 rounded-lg shadow-md flex flex-col justify-between transition-transform duration-300 hover:scale-105 ${
                    isCompleted
                      ? "border-8 border-green-500 rounded-lg relative"
                      : ""
                  }`}
                  style={{ height: "350px" }}
                >
                  {isCompleted && (
                    <div className="absolute inset-0 border-4 border-green-500 rounded-lg"></div>
                  )}
                  <img
                    src={setImage}
                    alt={`${set.name} Image`}
                    className="w-auto h-64 max-h-64 mx-auto mb-4 object-contain rounded-lg"
                  />
                  <p className="text-center font-bold">{setName}</p>
                  <p className="text-center text-sm">{studio}</p>
                  <p className="text-center text-sm">{set.editionType}</p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <p>No sets found for this series.</p>
      )}
    </div>
  );
};

export default PinnacleSeries;
