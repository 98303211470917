import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary ChartJS components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Legend
);

const CryptoKittiesEggsSets = () => {
  const [lineChartData, setLineChartData] = useState({});
  const [loading, setLoading] = useState(true); // Handle loading state

  useEffect(() => {
    const fetchLineChartData = async () => {
      try {
        const response = await fetch(
          "https://flipsidecrypto.xyz/api/v1/queries/688ccbf3-2060-4c63-b148-af5f9968329a/data/latest"
        );
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
          // Map snapshot date and corresponding rarity values
          const dates = data.map((item) => item.SNAPSHOT_DATE.split(" ")[0]);
          const common = data.map((item) => item.COMMON || 0);
          const uncommon = data.map((item) => item.UNCOMMON || 0);
          const rare = data.map((item) => item.RARE || 0);

          setLineChartData({
            labels: dates,
            datasets: [
              {
                label: "Common",
                data: common,
                borderColor: "#4BC0C0",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                fill: false,
              },
              {
                label: "Uncommon",
                data: uncommon,
                borderColor: "#FFCD56",
                backgroundColor: "rgba(255, 205, 86, 0.2)",
                fill: false,
              },
              {
                label: "Rare",
                data: rare,
                borderColor: "#FF6384",
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                fill: false,
              },
            ],
          });
        } else {
          console.error("No valid data found.");
          setLineChartData({});
        }
      } catch (error) {
        console.error("Error fetching line chart data:", error);
        setLineChartData({});
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    };

    fetchLineChartData();
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2 text-center">
          CK: Unique Wallets with Completed Sets Over Time (Daily)
        </h3>
        {loading ? (
          <p>Loading chart data...</p>
        ) : (
          <>
            {lineChartData.labels && lineChartData.labels.length > 0 ? (
              <div className="flex justify-center items-center">
                <div className="w-full" style={{ height: "400px" }}>
                  <Line
                    data={lineChartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              return `${context.dataset.label}: ${context.raw}`;
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          type: "category",
                          title: {
                            display: true,
                            text: "Snapshot Date (Daily)",
                          },
                          ticks: {
                            maxTicksLimit: 8,
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: "Number of Sets",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            ) : (
              <p>No data available for the line chart.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CryptoKittiesEggsSets;
