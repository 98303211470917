import React from "react";
import TopShotOvertime from "./charts/TopShotOvertime"; // Import from the charts directory
import TopShotBuyersSellers from "./charts/TopShotBuyersSellers";
import TopShotSalesValue from "./charts/TopShotSalesValue";
import TopShotFloors from "./charts/TopShotFloors";

const TopShotAnalytics = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        {/* Render the TopShotOvertime chart */}
        <TopShotOvertime />
      </div>
      <div className="mb-6">
        {/* Render the TopShotBuyersSellers chart */}
        <TopShotBuyersSellers />
      </div>
      <div className="mb-6">
        {/* Render the TopShotSalesValue chart */}
        <TopShotSalesValue />
      </div>
      <div className="mb-6">
        {/* Render the TopShotFloors chart */}
        <TopShotFloors />
      </div>

      {/* Section: External Resource */}
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">
          Additional Resources for In-Depth TopShot Analytics
        </h3>
        <p className="text-gray-700 mb-4">
          For more detailed analytics on NBA Top Shot moments, players, and
          market trends, you can explore OTM NFT. This is a paid external
          resource that provides insights beyond the data shown here.
        </p>

        <a
          href="https://www.otmnft.com/nbatopshot"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
        >
          Explore OTM NFT Analytics
        </a>
      </div>
    </div>
  );
};

export default TopShotAnalytics;
