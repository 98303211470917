import React from "react";
import communities from "../data/communities.js"; // Import your communities data

// Inline trackClick function
const trackClick = (category, label) => {
  if (window.gtag) {
    window.gtag("event", "click", {
      event_category: category,
      event_label: label,
    });
  }
};

const CommunitySection = ({
  logo,
  name,
  links,
  communityLink,
  background,
  logoBackground,
  teamLogo,
  teamLogoBackground,
}) => (
  <div
    className={`flex flex-col lg:flex-row items-center ${background} p-4 rounded-lg lg:w-auto`}
  >
    {/* Logo Section */}
    <a
      href={communityLink}
      target="_blank"
      rel="noopener noreferrer"
      className="hover:scale-105 transition-transform duration-300 flex-shrink-0"
      onClick={() => trackClick("Community Logo", `${name} Logo`)} // Track logo click
    >
      <div
        className={`p-1 rounded-full flex items-center justify-center h-24 w-24 ${logoBackground}`}
      >
        <img
          src={logo}
          alt={`${name} logo`}
          className="h-20 w-20 object-contain rounded-full"
        />
      </div>
    </a>

    {/* Team Logo if exists */}
    {teamLogo && (
      <div
        className={`h-12 w-12 mr-2 rounded-full p-1 border border-gray-300 ${teamLogoBackground} flex-shrink-0`}
      >
        <img
          src={teamLogo}
          alt={`${name} Team logo`}
          className="h-full w-full object-contain rounded-full"
        />
      </div>
    )}

    {/* Social Icons Section */}
    <div className={`mt-4 lg:ml-4 grid grid-cols-2 gap-2 lg:grid-cols-2`}>
      {links.map((item) => (
        <a
          key={item.name}
          href={item.link}
          className="text-white text-2xl hover:scale-105 transition-transform duration-300 text-center"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackClick("Social Icon", `${name} - ${item.name} Icon`)
          } // Track social icon click
        >
          {item.icon}
        </a>
      ))}
    </div>
  </div>
);

const CategoryContainer = ({ subcategoryName, communityList }) => (
  <div className="border border-gray-400 p-4 rounded-lg bg-gray-300 flex flex-col flex-grow">
    {/* Subcategory Name */}
    <div className="text-2xl font-bold text-center text-white mb-4">
      {subcategoryName}
    </div>

    {/* Communities within Subcategory */}
    <div className="flex flex-wrap gap-4 justify-start">
      {communityList.map((community) => (
        <CommunitySection
          key={community.name}
          logo={community.logo}
          name={community.name}
          communityLink={community.link}
          links={community.Official} // Show social media links
          background={community.background}
          logoBackground={community.logoBackground}
          teamLogo={community.teamLogo}
          teamLogoBackground={community.teamLogoBackground}
        />
      ))}
    </div>
  </div>
);

const TopShotCommunities = () => {
  // Filter the communities data for the specific TopShot-related categories
  const topShotCategories = ["TopShot Communities", "TopShot Fan Communities"];

  // Only display the categories that match the names above
  const filteredCategories = Object.entries(
    communities?.Communities?.subcategories || {}
  )
    .filter(([subcategoryName]) => topShotCategories.includes(subcategoryName))
    .map(([subcategoryName, communityList]) => ({
      subcategoryName,
      communityList,
    }));

  return (
    <div className="p-2 bg-gray-500">
      <div className="space-y-8">
        {filteredCategories.map(({ subcategoryName, communityList }) => (
          <CategoryContainer
            key={subcategoryName}
            subcategoryName={subcategoryName}
            communityList={communityList}
          />
        ))}
      </div>
    </div>
  );
};

export default TopShotCommunities;
