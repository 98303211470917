import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import topshotImage from "../logos/TopShot.png";
import nfladImage from "../logos/nflad.png";
import flow from "../logos/flow.svg";
import dapperlabs from "../logos/dapperlabs.svg";
import axiomzen from "../logos/axiomzen.svg";
import cryptokitties from "../logos/cryptokitties.svg";
import { content } from "../data/timeline";
import FlowTimeline from "../logos/FlowTimeline.svg";
import PinnacleLogo from "../logos/Pinnacle.png";
import Filter from "./TimelineFilter";
import TimeCircuits from "./TimeCircuits";

// Mapping internal type names to human-readable labels
const typeLabels = {
  NewProduct: "New Product",
  NewFeature: "New Feature",
  IRLEvent: "IRL Event",
  Partnership: "Partnership",
  Funding: "Funding",
  Personnel: "Personnel",
  Issue: "Issue",
  Merchandise: "Merchandise",
};

const getIconDetails = (iconType) => {
  switch (iconType) {
    case "TopShot":
      return { background: "white", icon: topshotImage };
    case "Flow":
      return { background: "white", icon: flow };
    case "DapperLabs":
      return { background: "white", icon: dapperlabs };
    case "AxiomZen":
      return { background: "white", icon: axiomzen };
    case "CryptoKitties":
      return { background: "white", icon: cryptokitties };
    case "Pinnacle":
      return { background: "gray", icon: PinnacleLogo };
    default:
      return { background: "gray", icon: null };
  }
};

// Group entries by year, month, and iconType
const groupEntries = (entries) => {
  const groupedEntries = {};

  entries.forEach((entry) => {
    const { year, month, iconType } = entry;
    const key = `${year}-${month}-${iconType}`;

    if (!groupedEntries[key]) {
      groupedEntries[key] = {
        year,
        month,
        iconType,
        items: [],
      };
    }

    groupedEntries[key].items.push(entry);
  });

  return Object.values(groupedEntries);
};

const Timeline = ({ defaultProject }) => {
  const allProjects = [
    "AxiomZen",
    "DapperLabs",
    "CryptoKitties",
    "Flow",
    "TopShot",
    "Pinnacle",
  ];

  // If defaultProject is provided, preselect only that project
  const [selectedProjects, setSelectedProjects] = useState(
    defaultProject ? [defaultProject] : allProjects
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleFilterChange = (iconType, isChecked) => {
    setSelectedProjects((prev) =>
      isChecked
        ? [...prev, iconType]
        : prev.filter((project) => project !== iconType)
    );
  };

  const groupedContent = groupEntries(content);

  return (
    <div className="bg-gray-300 p-3">
      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-gray-700 mb-4">
          This timeline provides a high-level overview of significant
          innovations and events within the Flow blockchain ecosystem. Specific
          Digital Collectible releases are out-of-scope unless part of a
          partnership or demonstrate innovation.
        </p>
        <p className="text-gray-700">
          Join the discussion and provide feedback on our{" "}
          <a
            href="https://discord.gg/5MU4ngQQ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Discord
          </a>
          .
        </p>
      </div>

      {/* Conditionally render the Filter only if there's no default project */}
      {!defaultProject && (
        <Filter
          iconTypes={allProjects}
          selectedIconTypes={selectedProjects}
          onFilterChange={handleFilterChange}
        />
      )}

      {/* Timeline Elements */}
      <VerticalTimeline>
        {groupedContent
          .filter((entry) => selectedProjects.includes(entry.iconType))
          .map((group, idx) => {
            const { background, icon } = getIconDetails(group.iconType);
            return (
              <VerticalTimelineElement
                key={`${group.year}-${group.month}-${group.iconType}-${idx}`}
                iconStyle={{
                  background,
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                contentStyle={{
                  background: "#f9fafb",
                  color: "#333",
                  paddingTop: "1rem",
                }}
                contentArrowStyle={{ borderRight: "10px solid #f9fafb" }}
                icon={
                  <div className="w-full h-full flex justify-center items-center">
                    <img
                      src={icon}
                      alt={group.iconType}
                      className="object-contain w-full h-full rounded-full"
                    />
                  </div>
                }
              >
                <div className="text-gray-600 text-sm font-semibold mb-2">
                  {`${group.month} ${group.year}`}
                </div>

                {group.items.map((item, idx) => (
                  <div key={idx}>
                    <div className="flex items-center mb-2">
                      <span className="inline-block bg-gray-200 text-gray-800 text-xs font-semibold px-2 py-1 rounded-full mr-2 whitespace-nowrap">
                        {typeLabels[item.type] || item.type}
                      </span>
                      <h4 className="font-bold text-blue-600 text-lg">
                        {item.title}
                      </h4>
                    </div>
                    <p className="text-gray-700 text-base mb-2 inline">
                      {item.details}
                      {item.link && (
                        <span>
                          {" "}
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            Read more here
                          </a>
                        </span>
                      )}
                    </p>
                  </div>
                ))}
                <div className="mb-4"></div>
              </VerticalTimelineElement>
            );
          })}
      </VerticalTimeline>
    </div>
  );
};

export default Timeline;
