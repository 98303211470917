import React from "react";

const TopShotGaming = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">TopShot Gaming - Fastbreak</h2>

      <p className="text-gray-700 mb-4">
        Fastbreak is a dynamic game developed by the NBA Top Shot team, where
        collectors compete by selecting players and earning rewards based on
        their performance during NBA Finals. Use your Moments strategically to
        climb the leaderboard and win exclusive prizes.
      </p>

      <div className="space-y-4">
        <a
          href="https://nbatopshot.com/fastbreak/lobby"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
        >
          Play Fastbreak Now!
        </a>

        <a
          href="https://about.nbatopshot.com/fastbreak"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
        >
          Learn More About Fastbreak
        </a>
      </div>

      <h3 className="text-xl font-bold mt-8 mb-4">Challenges</h3>
      <p className="text-gray-700 mb-4">
        Challenges give collectors an opportunity to use their Moments to earn
        exclusive prizes.
      </p>
      <a
        href="https://nbatopshot.com/challenges"
        target="_blank"
        rel="noopener noreferrer"
        className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
      >
        Explore Challenges
      </a>

      <h3 className="text-xl font-bold mt-8 mb-4">Trade-Ins & Leaderboards</h3>
      <p className="text-gray-700 mb-4">
        Trade in your Moments and compete to win newly released Moments from
        your favorite players, or lock your collection for long-term rewards on
        Player and Team Leaderboards.
      </p>
      <a
        href="https://nbatopshot.com/leaderboards"
        target="_blank"
        rel="noopener noreferrer"
        className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
      >
        View Leaderboards
      </a>
    </div>
  );
};

export default TopShotGaming;
