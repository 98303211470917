import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format, subMonths, subYears } from "date-fns"; // For date manipulation

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const AllDayFloors = () => {
  const [chartData, setChartData] = useState({});
  const [timeRange, setTimeRange] = useState("Last Year"); // Default to 'Last Year'
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://flipsidecrypto.xyz/api/v1/queries/2544aa6e-c6b6-4e0f-9537-540fa727a3c0/data/latest"
      );
      const data = await response.json();

      const activityWeeks = data.map((item) =>
        format(new Date(item.ACTIVITY_WEEK), "yyyy-MM-dd")
      );
      const resultPrices = data.map((item) => item.RESULT);

      setChartData({
        labels: activityWeeks,
        datasets: [
          {
            label: "Floor Price (USD)",
            data: resultPrices,
            borderColor: "rgba(75, 192, 192, 1)", // Teal
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: false,
            pointRadius: 0, // No point markers
          },
        ],
      });
    };

    fetchData();
  }, []);

  // Handle time range filtering
  useEffect(() => {
    if (chartData.labels) {
      const now = new Date();
      let filteredWeeks = chartData.labels;

      switch (timeRange) {
        case "Last Year":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subYears(now, 1)
          );
          break;
        case "Last 6 Months":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 6)
          );
          break;
        case "Last 3 Months":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 3)
          );
          break;
        case "Last Month":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 1)
          );
          break;
        default:
          filteredWeeks = chartData.labels; // All time
      }

      // Filter datasets based on the selected time range
      const updatedDatasets = chartData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.filter((_, idx) =>
          filteredWeeks.includes(chartData.labels[idx])
        ),
      }));

      setFilteredData({
        labels: filteredWeeks,
        datasets: updatedDatasets,
      });
    }
  }, [timeRange, chartData]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">NFL AllDay Floors Over Time</h3>

        {/* Description of the logic */}
        <div className="mb-4 p-4 bg-gray-100 rounded">
          <h4 className="font-semibold mb-2">
            How the Floor Price is Calculated
          </h4>
          <p>
            The floor price is calculated by analyzing weekly sales data from
            multiple platforms. For each week, we look at sales prices and
            exclude unusually low prices (which might be outliers). The lowest
            valid price for each week is chosen to represent the floor price.
          </p>
        </div>

        {/* Dropdown to select time range */}
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mb-4 p-2 border rounded"
        >
          <option value="All">All time</option>
          <option value="Last Year">Last Year</option>
          <option value="Last 6 Months">Last 6 Months</option>
          <option value="Last 3 Months">Last 3 Months</option>
          <option value="Last Month">Last Month</option>
        </select>

        {filteredData.labels ? (
          <Line
            data={filteredData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  enabled: true, // Show tooltip on hover
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${
                        tooltipItem.dataset.label
                      }: $${tooltipItem.raw.toLocaleString()}`;
                    },
                  },
                },
                datalabels: {
                  display: false, // Disable point labels
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "month",
                    displayFormats: {
                      month: "MMM yyyy",
                    },
                  },
                  ticks: {
                    maxTicksLimit: 12,
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Floor Price (USD)",
                  },
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default AllDayFloors;
