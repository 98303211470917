import React, { useState, useEffect } from "react";
import FlowLineChart from "./charts/FlowLineChart";
import FlowStackedAnalytics from "./charts/FlowStackedAnalytics";
import FlowTop5Projects from "./charts/FlowTop5Projects";

const FlowAnalytics = () => {
  const [expandedChart, setExpandedChart] = useState(null); // Track which chart is expanded
  const [isMobile, setIsMobile] = useState(false); // Detect if the device is mobile

  // Detect if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile threshold
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial state
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle chart expansion for mobile fullscreen landscape mode
  const toggleExpandChart = (chart) => {
    if (isMobile) {
      setExpandedChart(expandedChart === chart ? null : chart);
      if (expandedChart === chart) {
        // Exit fullscreen landscape mode on mobile
        document.exitFullscreen?.();
      } else {
        // Go fullscreen for mobile landscape
        document.documentElement.requestFullscreen?.();
      }
    } else {
      setExpandedChart(expandedChart === chart ? null : chart);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Main content */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 p-4 bg-white rounded-lg shadow-md flex-grow">
        {/* Line Chart */}
        <div
          className={`relative border p-4 rounded-lg shadow-lg bg-white flex-grow ${
            expandedChart === "line" ? "lg:col-span-2" : ""
          }`}
          style={{
            minHeight: isMobile
              ? "300px"
              : expandedChart === "line"
              ? "600px"
              : "500px",
            transition: "min-height 0.3s ease",
          }}
        >
          <FlowLineChart />
          {isMobile && !expandedChart && (
            <button
              className="absolute top-2 right-2 text-blue-500 underline"
              onClick={() => toggleExpandChart("line")}
            >
              Expand
            </button>
          )}
        </div>

        {/* Stacked Analytics */}
        <div
          className={`relative border p-4 rounded-lg shadow-lg bg-white flex-grow ${
            expandedChart === "stacked" ? "lg:col-span-2" : ""
          }`}
          style={{
            minHeight: isMobile
              ? "300px"
              : expandedChart === "stacked"
              ? "600px"
              : "500px",
            transition: "min-height 0.3s ease",
          }}
        >
          <FlowStackedAnalytics />
          {isMobile && !expandedChart && (
            <button
              className="absolute top-2 right-2 text-blue-500 underline"
              onClick={() => toggleExpandChart("stacked")}
            >
              Expand
            </button>
          )}
        </div>

        {/* Top 5 Projects */}
        <div
          className={`relative border p-4 rounded-lg shadow-lg bg-white flex-grow mb-12 ${
            expandedChart === "top5" ? "lg:col-span-2" : ""
          }`}
          style={{
            minHeight: isMobile
              ? "300px"
              : expandedChart === "top5"
              ? "600px"
              : "500px",
            transition: "min-height 0.3s ease",
          }}
        >
          <FlowTop5Projects />
          {isMobile && !expandedChart && (
            <button
              className="absolute top-2 right-2 text-blue-500 underline"
              onClick={() => toggleExpandChart("top5")}
            >
              Expand
            </button>
          )}
        </div>
      </div>
      {/* Exit button on mobile for expanded charts */}
      {isMobile && expandedChart && (
        <div className="fixed top-2 right-2 z-50">
          <button
            onClick={() => toggleExpandChart(null)}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg"
          >
            Exit Fullscreen
          </button>
        </div>
      )}
      {/* Section: Additional Resources */}
      <div className="my-12" />{" "}
      {/* Added more space between the last chart and More Analytics */}
      <div className="mt-8 p-4">
        <h3 className="text-xl font-bold mb-2">More Analytics</h3>
        <p className="text-gray-700 mb-4">
          For more detailed insights into Flow blockchain analytics, visit the
          Flipside Crypto dashboard.
        </p>
        <a
          href="https://flipsidecrypto.xyz/"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
        >
          Explore Flipside Crypto Analytics
        </a>
      </div>
      {/* Footer pushed to the bottom */}
      <footer className="bg-gray-200 py-4 mt-8">
        <div className="text-center text-gray-600">
          © 2024 Flow Analytics. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default FlowAnalytics;
