import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format, subMonths, subYears } from "date-fns"; // Use date-fns for date manipulation

// Register necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const collectionNamesMap = {
  "A.0b2a3299cc857e29.TopShot": {
    name: "TopShot",
    color: "rgba(255, 99, 132, 1)", // Red
  },
  "A.e4cf4bdc1751c65d.AllDay": {
    name: "NFLAllDay",
    color: "rgba(54, 162, 235, 1)", // Blue
  },
  "A.4eded0de73020ca5.CricketMoments": {
    name: "FanCrazeCricket",
    color: "rgba(255, 206, 86, 1)", // Yellow
  },
  "A.d0bcefdf1e67ea85.HWGarageCardV2": {
    name: "HotWheels",
    color: "rgba(255, 159, 64, 1)", // Orange
  },
  "A.329feb3ab062d289.UFC_NFT": {
    name: "UFC",
    color: "rgba(75, 192, 192, 1)", // Teal
  },
};

const FlowTop5Projects = () => {
  const [chartData, setChartData] = useState({});
  const [timeRange, setTimeRange] = useState("Last Year"); // Default to 'All time'
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://flipsidecrypto.xyz/api/v1/queries/7e1135ed-aca4-4f21-bf5c-d04f2e7dd961/data/latest"
      );
      const data = await response.json();

      // Ensure all collections are present and weeks are filled
      const collectionsData = {};
      const allWeeks = new Set();

      // Process data for the specified collections
      data.forEach((item) => {
        const collection = item.NFT_COLLECTION;
        if (collectionNamesMap[collection]) {
          const collectionName = collectionNamesMap[collection].name;
          if (!collectionsData[collectionName]) {
            collectionsData[collectionName] = [];
          }
          const weekFormatted = format(
            new Date(item.ACTIVITY_WEEK),
            "yyyy-MM-dd"
          );
          collectionsData[collectionName].push({
            week: weekFormatted,
            value: item.DAILY_ACTIVE_NFT_BUYERS,
          });
          allWeeks.add(weekFormatted);
        }
      });

      // Sort the weeks in ascending order
      const sortedWeeks = Array.from(allWeeks).sort();

      // Fill missing weeks with null values for each collection
      Object.keys(collectionsData).forEach((collection) => {
        const weekMap = new Map(
          collectionsData[collection].map((item) => [item.week, item.value])
        );
        collectionsData[collection] = sortedWeeks.map((week) => ({
          week,
          value: weekMap.get(week) || null, // Fill with null if no data for the week
        }));
      });

      setChartData({ collectionsData, sortedWeeks });
    };

    fetchData();
  }, []);

  // Handle time range filtering
  useEffect(() => {
    if (chartData.collectionsData && chartData.sortedWeeks) {
      const now = new Date();
      let filteredWeeks = chartData.sortedWeeks;

      switch (timeRange) {
        case "Last Year":
          filteredWeeks = chartData.sortedWeeks.filter(
            (week) => new Date(week) >= subYears(now, 1)
          );
          break;
        case "Last 6 Months":
          filteredWeeks = chartData.sortedWeeks.filter(
            (week) => new Date(week) >= subMonths(now, 6)
          );
          break;
        case "Last 3 Months":
          filteredWeeks = chartData.sortedWeeks.filter(
            (week) => new Date(week) >= subMonths(now, 3)
          );
          break;
        case "Last Month":
          filteredWeeks = chartData.sortedWeeks.filter(
            (week) => new Date(week) >= subMonths(now, 1)
          );
          break;
        default:
          filteredWeeks = chartData.sortedWeeks; // All time
      }

      // Update datasets for the selected time range
      const updatedDatasets = Object.keys(chartData.collectionsData).map(
        (collection) => {
          const collectionInfo = Object.values(collectionNamesMap).find(
            (entry) => entry.name === collection
          );
          return {
            label: collection,
            data: chartData.collectionsData[collection]
              .filter((item) => filteredWeeks.includes(item.week))
              .map((item) => item.value),
            borderColor: collectionInfo?.color, // Use color from collectionNamesMap
            backgroundColor: collectionInfo?.color,
            pointRadius: 0,
            spanGaps: true,
          };
        }
      );

      setFilteredData({
        labels: filteredWeeks,
        datasets: updatedDatasets,
      });
    }
  }, [timeRange, chartData]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          Weekly Active NFT Buyers for Selected Collections
        </h3>
        {/* Dropdown to select time range */}
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mb-4 p-2 border rounded"
        >
          <option value="All">All time</option>
          <option value="Last Year">Last year</option>
          <option value="Last 6 Months">Last 6 months</option>
          <option value="Last 3 Months">Last 3 months</option>
          <option value="Last Month">Last month</option>
        </select>

        {filteredData.labels ? (
          <Line
            data={filteredData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                  text: "Weekly Active NFT Buyers by Collection",
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`; // Display label and value
                    },
                  },
                },
                datalabels: {
                  display: false, // Disable data point labels
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "week", // Show weekly data
                    displayFormats: {
                      week: "MMM yyyy",
                    },
                  },
                  ticks: {
                    maxTicksLimit: 10, // Limit the number of ticks on the x-axis
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Weekly Active NFT Buyers",
                  },
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default FlowTop5Projects;
