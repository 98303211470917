import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import editionNames from "../data/editionNames.json";
import { parseUnicode, getSetBackground, formatDate } from "./PinnacleHelpers";
import { UserContext } from "./UserContext";
import { FaSpinner } from "react-icons/fa";
import { loadEditionImage } from "./GenericHelpers";

// Helper function to fetch data from given API URL
const fetchDataFromAPI = async (url, errorMessage) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`${errorMessage}: ${response.statusText}`);
  }
  return response.json();
};

// Function to calculate circulated pins
const calculateCirculated = (
  minted = 0,
  capsuleVault = 0,
  promoVault = 0,
  burn = 0,
  pinnacleVault = 0
) => minted - capsuleVault - promoVault - burn - pinnacleVault;

const PinnacleSets = () => {
  const { setId } = useParams();
  const navigate = useNavigate();
  const [setData, setSetData] = useState(null);
  const [editions, setEditions] = useState([]);
  const [vaultData, setVaultData] = useState({
    CapsuleVault: {},
    PromoVault: {},
    PinnacleVault: {},
  });
  const [burnData, setBurnData] = useState({});
  const [error, setError] = useState(null);
  const { user, nftCollection } = useContext(UserContext);
  const [imageUrls, setImageUrls] = useState({}); // State to store image URLs for each edition

  // Observer for lazy loading images
  const imgObserver = useRef(null);

  // Fetch set details, editions, and vault data based on setId
  useEffect(() => {
    const fetchSetData = async () => {
      try {
        const setsData = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-sets",
          "Failed to fetch sets data"
        );
        const currentSet = setsData.find((set) => set.id === Number(setId));
        if (!currentSet) throw new Error(`Set with ID ${setId} not found`);

        setSetData(currentSet);

        const allEditions = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions",
          "Failed to fetch editions data"
        );
        setEditions(
          allEditions.filter((edition) => edition.setID === Number(setId))
        );

        const vaultDataRes = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-vaults",
          "Failed to fetch vaults data"
        );
        const vaultDataMap = vaultDataRes.reduce(
          (acc, vault) => {
            if (vault.vault_address === "0xb6f2481eba4df97b") {
              acc.CapsuleVault[vault.editionID] = vault.quantity;
            } else if (vault.vault_address === "0xd708cc29ee72b8ee") {
              acc.PromoVault[vault.editionID] = vault.quantity;
            }
            return acc;
          },
          { CapsuleVault: {}, PromoVault: {}, PinnacleVault: {} }
        );

        const pinnacleVaultData = await fetchDataFromAPI(
          "https://flipsidecrypto.xyz/api/v1/queries/83a51bfc-3d5c-408e-85c4-0bf0a0109544/data/latest",
          "Failed to fetch PinnacleVault data"
        );
        pinnacleVaultData.forEach((vault) => {
          if (vault.VAULT_ADDRESS === "0xedf9df96c92f4595") {
            vaultDataMap.PinnacleVault[vault.EDITIONID] = vault.QUANTITY;
          }
        });

        setVaultData(vaultDataMap);

        const burnDataRes = await fetchDataFromAPI(
          "https://flipsidecrypto.xyz/api/v1/queries/cdb2b538-ad5f-46a4-957d-c17f52e044fa/data/latest",
          "Failed to fetch burn data"
        );
        const burnDataMap = burnDataRes.reduce((acc, burn) => {
          acc[burn.EDITIONID] = burn.BURN_COUNT;
          return acc;
        }, {});
        setBurnData(burnDataMap);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSetData();
  }, [setId]);

  // Lazy load observer setup
  useEffect(() => {
    imgObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const imgUrl = img.getAttribute("data-src");
          if (img && imgUrl) {
            img.src = imgUrl;
          }
          imgObserver.current.unobserve(img); // Stop observing once loaded
        }
      });
    });

    return () => {
      if (imgObserver.current) {
        imgObserver.current.disconnect();
      }
    };
  }, []);

  // Fetch images for editions
  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = editions.map((edition) =>
        loadEditionImage(edition.id, "pinnacle").then((imageUrl) => ({
          id: edition.id,
          url: imageUrl,
        }))
      );

      const loadedImages = await Promise.all(imagePromises);
      const imageMap = loadedImages.reduce(
        (acc, { id, url }) => ({ ...acc, [id]: url }),
        {}
      );
      setImageUrls(imageMap); // Store image URLs for all editions
    };

    if (editions.length > 0) {
      loadImages();
    }
  }, [editions]);

  if (error) {
    return <div className="p-8 text-red-500">Error: {error}</div>;
  }

  if (!setData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
        <span className="ml-2 text-xl">Loading...</span>
      </div>
    );
  }

  const renderEditionCard = (edition) => {
    const ownedCount =
      user?.loggedIn && nftCollection?.[edition.id]
        ? nftCollection[edition.id].length
        : 0;
    const capsuleVault = vaultData.CapsuleVault?.[edition.id] || 0;
    const promoVault = vaultData.PromoVault?.[edition.id] || 0;
    const pinnacleVault = vaultData.PinnacleVault?.[edition.id] || 0;
    const burnCount = burnData?.[edition.id] || 0;
    const minted = edition.numberMinted || 0;

    const circulated = calculateCirculated(
      minted,
      capsuleVault,
      promoVault,
      burnCount,
      pinnacleVault
    );

    return (
      <div
        key={edition.id}
        className={`relative border px-4 py-2 rounded-lg shadow-md text-left flex flex-col justify-between transition-transform duration-300 hover:scale-105 cursor-pointer ${getSetBackground(
          setData.editionType
        )} text-slate-200 ${ownedCount > 0 ? "border-4 border-green-400" : ""}`}
        onClick={() => navigate(`/pinnacle/editions/${edition.id}`)}
      >
        {/* Total Owned in Green Circle */}
        {ownedCount > 0 && (
          <div className="absolute top-2 left-2 bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold z-10">
            {ownedCount}X
          </div>
        )}

        <div>
          {/* Edition Image */}
          <img
            data-src={imageUrls[edition.id] || ""}
            alt={`Edition ${edition.id}`}
            loading="lazy"
            className="w-auto h-48 mx-auto mb-4 object-cover rounded-lg"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite error loop
              e.target.src =
                "https://storage.googleapis.com/flowconnect/pinnacle/pins/placeholder.png";
            }}
            ref={(img) => {
              if (img && imgObserver.current) {
                imgObserver.current.observe(img); // Ensure img is valid before observing
              }
            }}
          />

          {/* Edition Name */}
          <h3 className="text-lg font-bold text-center">
            {editionNames[edition.id] || "Unknown Edition"}{" "}
            {edition.isChaser && <span className="text-yellow-500"> ★</span>}
          </h3>

          {/* Edition Details */}
          <p className="text-sm mt-2">
            <strong>EditionID:</strong> {edition.id}
          </p>
          <p className="text-sm">
            <strong>Variant:</strong> {edition.variant}
          </p>

          {/* Edition Traits */}
          <div className="text-sm mt-2">
            {edition.traits?.Materials?.length > 0 && (
              <p>
                <strong>Materials:</strong>{" "}
                {edition.traits.Materials.join(", ")}
              </p>
            )}
            {edition.traits?.Effects?.length > 0 && (
              <p>
                <strong>Effects:</strong> {edition.traits.Effects.join(", ")}
              </p>
            )}
          </div>

          {/* Creation and Closed Dates */}
          <p className="text-sm mt-2">
            <strong>Creation Date:</strong> {formatDate(edition.creationDate)}
          </p>
          {edition.closedDate && (
            <p className="text-sm">
              <strong>Closed Date:</strong> {formatDate(edition.closedDate)}
            </p>
          )}

          {/* Circulation and Burn Data Table */}
          <table className="w-full mt-4 text-left text-sm">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="text-black">
              <tr className="bg-green-100">
                <td>Minted</td>
                <td>{minted}</td>
              </tr>
              <tr className="bg-yellow-100">
                <td>CapsuleVault</td>
                <td>{capsuleVault}</td>
              </tr>
              <tr className="bg-purple-100">
                <td>PromoVault</td>
                <td>{promoVault}</td>
              </tr>
              <tr className="bg-blue-100">
                <td>PinnacleVault</td>
                <td>{pinnacleVault}</td>
              </tr>
              <tr className="bg-red-100">
                <td>Burned</td>
                <td>{burnCount}</td>
              </tr>
              <tr className="bg-gray-100">
                <td>Circulated</td>
                <td>{circulated}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="p-8">
      {/* Back Button */}
      <button
        className="mb-4 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        onClick={() => navigate(`/pinnacle/series/${setData.seriesID}`)}
      >
        ← Back
      </button>

      {/* Display the parsed set name */}
      <h1 className="text-3xl font-bold mb-4">{parseUnicode(setData.name)}</h1>

      {/* Display the edition type */}
      <p className="text-xl mb-4">{setData.editionType}</p>

      {/* Display editions for this set */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-2">
        {editions.map((edition) => renderEditionCard(edition))}
      </div>
    </div>
  );
};

export default PinnacleSets;
