import React from "react";

const CryptoKittiesExplorer = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">CryptoKitties Explorer</h2>
      <p>Explore your CryptoKitties here! (Data will be added soon)</p>
      <p>
        We might add more features here in the future, but for now, the best
        resources to explore CryptoKitties are:
      </p>
      <ul className="list-disc list-inside">
        <li>
          <a
            href="https://www.cryptokitties.co/catalogue"
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Official CryptoKitties Catalogue
          </a>
        </li>
        <li>
          <a
            href="https://kittyhelper.co/"
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            KittyHelper
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CryptoKittiesExplorer;
