import React from "react";
import { Link } from "react-router-dom";

const TopShotSeries = ({
  series,
  selectedSeries,
  setData,
  cachedSetImages,
  completedSetIDs,
  getTopShotSetTier,
  getSetBackground,
}) => {
  // Filter sets based on the selected series and exclude sets with IDs 155, 156, and 158
  const filteredSets =
    selectedSeries !== null
      ? setData.filter(
          (set) =>
            set.series === selectedSeries && ![155, 156, 158].includes(set.id)
        )
      : [];

  return (
    <div>
      {/* Series tabs */}
      <div className="flex space-x-2 mb-4 mt-2">
        {series.map((serie) => (
          <Link
            key={serie.id}
            to={`/topshot/series/${serie.id}`}
            className={`px-4 py-2 rounded-lg border transition-transform duration-300 hover:scale-105 ${
              selectedSeries === serie.id
                ? "bg-green-500 text-white"
                : "bg-white border-green-500 text-black"
            }`}
          >
            {`Series ${serie.id}`}
          </Link>
        ))}
      </div>

      {/* Sets view */}
      {filteredSets.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {filteredSets.map((set) => {
            const isCompleted = completedSetIDs.includes(set.id);
            const setImage =
              cachedSetImages[set.id] ||
              "https://storage.googleapis.com/flowconnect/topshot/sets/placeholder.jpg";

            // Get the tier of the set and pass it to get the background color
            const setTier = getTopShotSetTier(set.id);
            const backgroundColor = getSetBackground("TopShot", setTier);

            return (
              <Link
                to={`/topshot/sets/${set.id}`}
                key={set.id}
                className="no-underline"
              >
                <div
                  className={`${backgroundColor} text-slate-200 p-4 rounded-lg shadow-md flex flex-col justify-between transition-transform duration-300 hover:scale-105 ${
                    isCompleted
                      ? "border-8 border-green-500 rounded-lg relative"
                      : ""
                  }`}
                  style={{ height: "350px" }}
                >
                  {isCompleted && (
                    <div className="absolute inset-0 border-4 border-green-500 rounded-lg"></div>
                  )}
                  <img
                    src={setImage}
                    alt={`${set.name} Image`}
                    className="w-auto h-64 max-h-64 mx-auto mb-4 object-contain rounded-lg"
                  />

                  <p className="text-center font-bold">{set.name}</p>
                  <p className="text-center text-sm">
                    {set.locked ? "Locked" : "Open"}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <p className="text-center text-lg text-gray-600">
          No sets found for this series.
        </p>
      )}
    </div>
  );
};

export default TopShotSeries;
