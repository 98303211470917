import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import editionNames from "../data/editionNames.json";
import {
  parseUnicode,
  getSetBackground,
  formatDate,
  calculateCirculated,
  getApiEndpoint,
  getPagination,
  fetchDataFromAPI,
} from "./PinnacleHelpers"; // Import utility functions from PinnacleHelpers
import { loadEditionImage } from "./GenericHelpers";
import { UserContext } from "./UserContext";

const PinnacleEdition = () => {
  const { editionId } = useParams();
  const navigate = useNavigate();
  const [editionData, setEditionData] = useState(null);
  const [owners, setOwners] = useState([]);
  const [error, setError] = useState(null);
  const [editionImage, setEditionImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [vaultData, setVaultData] = useState({
    CapsuleVault: {},
    PromoVault: {},
    PinnacleVault: {},
  });
  const [burnData, setBurnData] = useState({});
  const { user } = useContext(UserContext);
  const ownersPerPage = 10;

  // Fetch edition details, vault, and burn data
  useEffect(() => {
    const fetchEditionData = async () => {
      try {
        const editions = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions",
          "Failed to fetch editions data"
        );
        const currentEdition = editions.find(
          (edition) => edition.id === Number(editionId)
        );
        if (!currentEdition)
          throw new Error(`Edition with ID ${editionId} not found`);
        setEditionData(currentEdition);

        const imageSrc = await loadEditionImage(editionId, "pinnacle");
        setEditionImage(imageSrc); // Ensure that the imageSrc is set properly
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchAdditionalData = async () => {
      try {
        const apiEndpoint = getApiEndpoint(Number(editionId));
        if (!apiEndpoint)
          throw new Error("Invalid edition ID or no data available");

        const ownersData = await fetchDataFromAPI(
          apiEndpoint,
          "Failed to fetch owners data"
        );
        setOwners(
          ownersData
            .filter((owner) => String(owner.EDITIONID) === String(editionId))
            .slice(0, 500)
        );

        const vaultDataRes = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-vaults",
          "Failed to fetch vault data"
        );
        const vaultDataMap = vaultDataRes.reduce(
          (acc, vault) => {
            if (vault.vault_address === "0xb6f2481eba4df97b") {
              acc.CapsuleVault[vault.editionID] = vault.quantity;
            } else if (vault.vault_address === "0xd708cc29ee72b8ee") {
              acc.PromoVault[vault.editionID] = vault.quantity;
            }
            return acc;
          },
          { CapsuleVault: {}, PromoVault: {}, PinnacleVault: {} }
        );

        const pinnacleVaultRes = await fetchDataFromAPI(
          "https://flipsidecrypto.xyz/api/v1/queries/83a51bfc-3d5c-408e-85c4-0bf0a0109544/data/latest",
          "Failed to fetch PinnacleVault data"
        );
        pinnacleVaultRes.forEach((vault) => {
          if (vault.VAULT_ADDRESS === "0xedf9df96c92f4595") {
            vaultDataMap.PinnacleVault[vault.EDITIONID] = vault.QUANTITY;
          }
        });

        setVaultData(vaultDataMap);

        const burnDataRes = await fetchDataFromAPI(
          "https://flipsidecrypto.xyz/api/v1/queries/cdb2b538-ad5f-46a4-957d-c17f52e044fa/data/latest",
          "Failed to fetch burn data"
        );
        const burnDataMap = burnDataRes.reduce((acc, burn) => {
          acc[burn.EDITIONID] = burn.BURN_COUNT;
          return acc;
        }, {});
        setBurnData(burnDataMap);
      } catch (err) {
        console.error(err);
      }
    };

    fetchEditionData();
    fetchAdditionalData();
  }, [editionId]);

  if (error) {
    return <div className="p-8 text-red-500">Error: {error}</div>;
  }

  if (!editionData) {
    return <div>Loading...</div>;
  }

  // Pagination logic
  const totalPages = Math.ceil(owners.length / ownersPerPage);
  const currentOwners = owners.slice(
    (currentPage - 1) * ownersPerPage,
    currentPage * ownersPerPage
  );
  const paginationButtons = getPagination(totalPages, currentPage);

  // Handlers for pagination
  const handleNext = () =>
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  const handlePrev = () =>
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get edition name
  const editionName = editionNames[editionId] || "Unknown Edition";

  return (
    <div className="p-8">
      {/* Back Button */}
      <button
        className="mb-4 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        onClick={() => navigate(`/pinnacle/sets/${editionData.setID}`)}
      >
        ← Back
      </button>

      {/* Display edition information */}
      <h1 className="text-3xl font-bold mb-4">{parseUnicode(editionName)}</h1>
      <div
        className={`relative border px-4 py-2 rounded-lg shadow-md text-left flex flex-col justify-between ${getSetBackground(
          editionData.editionTypeID
        )} text-slate-200`}
      >
        <img
          src={editionImage || "/images/placeholder.png"}
          alt={`Edition ${editionId}`}
          className="w-auto h-48 mx-auto mb-4 object-cover"
        />
        <h3 className="text-lg font-bold text-center">
          {editionName}{" "}
          {editionData.isChaser && <span className="text-yellow-500"> ★</span>}
        </h3>

        {/* Edition Details */}
        <p className="text-sm mt-2">
          <strong>Edition ID:</strong> {editionData.id} &nbsp;
          <strong>Variant:</strong> {editionData.variant}
        </p>
        <div className="text-sm">
          {editionData.traits?.Materials?.length > 0 && (
            <p>
              <strong>Materials:</strong>{" "}
              {editionData.traits.Materials.join(", ")}
            </p>
          )}
          {editionData.traits?.Effects?.length > 0 && (
            <p>
              <strong>Effects:</strong> {editionData.traits.Effects.join(", ")}
            </p>
          )}
        </div>

        {/* Creation and Closed Dates */}
        <p className="text-sm mt-2">
          <strong>Creation Date:</strong> {formatDate(editionData.creationDate)}
        </p>
        {editionData.closedDate && (
          <p className="text-sm">
            <strong>Closed Date:</strong> {formatDate(editionData.closedDate)}
          </p>
        )}

        {/* Edition Description */}
        <p className="text-sm mt-4">{editionData.description}</p>

        {/* Circulation and Burn Data Table */}
        <table className="w-full mt-4 text-left text-sm">
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody className="text-black">
            <tr className="bg-green-100">
              <td>Minted</td>
              <td>{editionData.numberMinted || 0}</td>
            </tr>
            <tr className="bg-yellow-100">
              <td>CapsuleVault</td>
              <td>{vaultData.CapsuleVault[editionId] || 0}</td>
            </tr>
            <tr className="bg-purple-100">
              <td>PromoVault</td>
              <td>{vaultData.PromoVault[editionId] || 0}</td>
            </tr>
            <tr className="bg-blue-100">
              <td>PinnacleVault</td>
              <td>{vaultData.PinnacleVault[editionId] || 0}</td>
            </tr>
            <tr className="bg-red-100">
              <td>Burned</td>
              <td>{burnData[editionId] || 0}</td>
            </tr>
            <tr className="bg-gray-100">
              <td>Circulated</td>
              <td>
                {calculateCirculated(
                  editionData.numberMinted || 0, // Ensures a default value of 0 if numberMinted is undefined
                  vaultData.CapsuleVault[editionId] || 0, // Ensures a default value of 0
                  vaultData.PromoVault[editionId] || 0, // Ensures a default value of 0
                  burnData[editionId] || 0, // Ensures a default value of 0
                  vaultData.PinnacleVault[editionId] || 0 // Ensures a default value of 0
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Owners Table */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">
          Top 500 Owners of this Edition
        </h2>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="border px-4 py-2">Owner Wallet</th>
              <th className="border px-4 py-2">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {currentOwners.length > 0 ? (
              currentOwners.map((owner, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border px-4 py-2">{owner.OWNER_WALLET}</td>
                  <td className="border px-4 py-2">{owner.QUANTITY}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border px-4 py-2 text-center" colSpan="2">
                  No owners found for this edition.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="mt-4 flex justify-center space-x-2">
          <button
            onClick={handlePrev}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded ${
              currentPage === 1
                ? "bg-gray-200 text-gray-400"
                : "bg-gray-300 text-gray-800 hover:bg-gray-400"
            }`}
          >
            Prev
          </button>

          {paginationButtons.map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && paginate(page)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === page
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              disabled={typeof page !== "number"}
            >
              {page}
            </button>
          ))}

          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded ${
              currentPage === totalPages
                ? "bg-gray-200 text-gray-400"
                : "bg-gray-300 text-gray-800 hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PinnacleEdition;
