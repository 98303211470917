import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format, subMonths, subYears } from "date-fns"; // For date manipulation

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const AllDaySalesValue = () => {
  const [chartData, setChartData] = useState({
    weeks: [],
    totalSalesValue: [],
  });
  const [timeRange, setTimeRange] = useState("Last Year"); // Default to 'Last Year'
  const [filteredData, setFilteredData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://flipsidecrypto.xyz/api/v1/queries/9306d3ef-1317-4cd3-991a-0289047c2a84/data/latest"
        );
        const data = await response.json();

        // Ensure the data exists before mapping
        if (Array.isArray(data) && data.length > 0) {
          const activityWeeks = data.map((item) =>
            format(new Date(item.ACTIVITY_WEEK), "yyyy-MM-dd")
          );

          const totalSalesValue = data.map((item) => item.TOTAL_SALES_VALUE);

          setChartData({
            weeks: activityWeeks,
            totalSalesValue,
          });
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle time range filtering
  useEffect(() => {
    if (chartData.weeks.length > 0) {
      const now = new Date();
      let filteredWeeks = chartData.weeks;

      switch (timeRange) {
        case "Last Year":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subYears(now, 1)
          );
          break;
        case "Last 6 Months":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subMonths(now, 6)
          );
          break;
        case "Last 3 Months":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subMonths(now, 3)
          );
          break;
        case "Last Month":
          filteredWeeks = chartData.weeks.filter(
            (week) => new Date(week) >= subMonths(now, 1)
          );
          break;
        default:
          filteredWeeks = chartData.weeks; // All time
      }

      const filteredSalesValue = chartData.totalSalesValue.slice(
        chartData.weeks.indexOf(filteredWeeks[0]),
        chartData.weeks.indexOf(filteredWeeks[filteredWeeks.length - 1]) + 1
      );

      setFilteredData({
        labels: filteredWeeks,
        datasets: [
          {
            label: "Total Sales Volume (USD)",
            data: filteredSalesValue,
            borderColor: "rgba(54, 162, 235, 1)", // Blue
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: false, // Disable the fill
            pointRadius: 0,
          },
        ],
      });
    }
  }, [timeRange, chartData]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h3 className="text-xl font-bold mb-4">
        NFL AllDay: Total Sales Volume Over Time
      </h3>

      {/* Dropdown to select time range */}
      <div className="mb-6">
        <label htmlFor="timeRange" className="block mb-2 font-semibold">
          Select Date Range:
        </label>
        <select
          id="timeRange"
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="All">All time</option>
          <option value="Last Year">Last Year</option>
          <option value="Last 6 Months">Last 6 Months</option>
          <option value="Last 3 Months">Last 3 Months</option>
          <option value="Last Month">Last Month</option>
        </select>
      </div>

      {loading ? (
        <p>Loading chart data...</p>
      ) : filteredData.labels && filteredData.datasets ? (
        <Line
          data={filteredData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (tooltipItem) {
                    return `${
                      tooltipItem.dataset.label
                    }: $${tooltipItem.raw.toLocaleString()}`;
                  },
                },
              },
              datalabels: {
                display: false, // Disable data point labels
              },
            },
            scales: {
              x: {
                type: "time",
                time: {
                  unit: "month",
                  displayFormats: {
                    month: "MMM yyyy",
                  },
                },
                ticks: {
                  maxTicksLimit: 12,
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Sales Volume (USD)",
                },
              },
            },
          }}
        />
      ) : (
        <p>No data available for the selected range.</p>
      )}
    </div>
  );
};

export default AllDaySalesValue;
