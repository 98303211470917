import React, { useEffect, useState } from "react";

const CryptoTicker = () => {
  const [prices, setPrices] = useState({ bitcoin: {}, ethereum: {}, flow: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/api/crypto/prices-7d"
        );
        if (!response.ok) throw new Error("Failed to fetch prices");
        const data = await response.json();

        const extractPriceData = (cryptoData) => {
          const prices = cryptoData.prices;
          if (!prices || prices.length < 2) {
            return {
              latestPrice: 0,
              oneDayAgoPrice: 0,
              sevenDaysAgoPrice: 0,
              oneDayChange: 0,
              sevenDayChange: 0,
            };
          }
          const latestPrice = prices[prices.length - 1][1];
          const oneDayAgoPrice = prices[prices.length - 2][1];
          const sevenDaysAgoPrice = prices[0][1];

          return {
            latestPrice,
            oneDayAgoPrice,
            sevenDaysAgoPrice,
            oneDayChange:
              ((latestPrice - oneDayAgoPrice) / oneDayAgoPrice) * 100,
            sevenDayChange:
              ((latestPrice - sevenDaysAgoPrice) / sevenDaysAgoPrice) * 100,
          };
        };

        setPrices({
          bitcoin: extractPriceData(data.bitcoin),
          ethereum: extractPriceData(data.ethereum),
          flow: extractPriceData(data.flow),
        });
        setError(false);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch prices", error);
        setError(true);
        setLoading(false);
      }
    };

    fetchPrices();
  }, []);

  if (loading) {
    return <div>Loading prices...</div>;
  }

  const formatPercentage = (value) => {
    return value > 0 ? `+${value.toFixed(2)}%` : `${value.toFixed(2)}%`;
  };

  return (
    <div className="crypto-price-table bg-gray-100 p-2 rounded-lg shadow-lg">
      {error && (
        <div className="text-red-500 text-sm">Failed to load prices.</div>
      )}
      <table className="w-full text-left text-sm">
        <thead>
          <tr className="border-b border-gray-300">
            <th className="pb-1">Name</th>
            <th className="pb-1">Price (USD)</th>
            <th className="pb-1">1 Day %</th>
            <th className="pb-1">7 Day %</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-gray-200">
            <td className="py-1">Bitcoin (BTC)</td>
            <td className="py-1">
              ${prices.bitcoin.latestPrice?.toFixed(2) || "N/A"}
            </td>
            <td
              className={`py-1 ${
                prices.bitcoin.oneDayChange > 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {prices.bitcoin.oneDayChange
                ? formatPercentage(prices.bitcoin.oneDayChange)
                : "N/A"}
            </td>
            <td
              className={`py-1 ${
                prices.bitcoin.sevenDayChange > 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {prices.bitcoin.sevenDayChange
                ? formatPercentage(prices.bitcoin.sevenDayChange)
                : "N/A"}
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="py-1">Ethereum (ETH)</td>
            <td className="py-1">
              ${prices.ethereum.latestPrice?.toFixed(2) || "N/A"}
            </td>
            <td
              className={`py-1 ${
                prices.ethereum.oneDayChange > 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {prices.ethereum.oneDayChange
                ? formatPercentage(prices.ethereum.oneDayChange)
                : "N/A"}
            </td>
            <td
              className={`py-1 ${
                prices.ethereum.sevenDayChange > 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {prices.ethereum.sevenDayChange
                ? formatPercentage(prices.ethereum.sevenDayChange)
                : "N/A"}
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="py-1">Flow (FLOW)</td>
            <td className="py-1">
              ${prices.flow.latestPrice?.toFixed(2) || "N/A"}
            </td>
            <td
              className={`py-1 ${
                prices.flow.oneDayChange > 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {prices.flow.oneDayChange
                ? formatPercentage(prices.flow.oneDayChange)
                : "N/A"}
            </td>
            <td
              className={`py-1 ${
                prices.flow.sevenDayChange > 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {prices.flow.sevenDayChange
                ? formatPercentage(prices.flow.sevenDayChange)
                : "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CryptoTicker;
