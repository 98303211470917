import React from "react";
import PinnacleCirculation from "./charts/PinnacleCirculation";
import PinnacleCollectors from "./charts/PinnacleCollectors";

const PinnacleAnalytics = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      {/* Description Section */}
      <div className="mb-6">
        <p className="text-md text-gray-700 mb-4">
          The data on this page is refreshed every 24 hours. If you'd like to
          see any specific data or have suggestions, feel free to join our
          Discord community:{" "}
          <a
            href="https://discord.gg/5MU4ngQQ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Join Discord
          </a>
          .
        </p>
        <p className="text-md text-gray-700">
          The Pin Explorer contains details about the number of pins in
          circulation, and we're continuously working to add more information
          and expand it further.
        </p>
      </div>

      {/* Circulation Chart */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          Total Circulation, Minted, and Burned Pins Over Time
        </h3>
        <PinnacleCirculation />
      </div>

      {/* Collectors and Traders Chart */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          Total Collectors and Traders Over Time
        </h3>
        <PinnacleCollectors />
      </div>

      {/* Section 1: Diamond Dashboards */}
      <div>
        <h3 className="text-xl font-bold mb-2">
          Diamond's Pinnacle Dashboards
        </h3>
        <p className="text-gray-700 mb-4">
          For an even deeper dive into Pinnacle’s analytics, including more
          complex metrics, trends, and visualizations, check out Diamond’s
          Pinnacle Dashboards on Flipside Crypto. This provides a broader
          overview and helps track performance across the Pinnacle ecosystem.
        </p>
        <a
          href="https://flipsidecrypto.xyz/Diamond/dashboards?search=pinnacle&sortBy=new"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
        >
          View Diamond's Pinnacle Dashboards
        </a>
      </div>
    </div>
  );
};

export default PinnacleAnalytics;
