import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaInstagram,
  FaTwitter,
  FaDiscord,
  FaReddit,
  FaTelegram,
} from "react-icons/fa";
import CryptoKittiesExplorer from "./CryptoKittiesExplorer";
import AllTheZen from "./AllTheZen";
import CryptoKittiesAnalytics from "./CryptoKittiesAnalytics";
import CryptoKittiesLogo from "../logos/cryptokitties.svg"; // Correct path for the logo

const CryptoKitties = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  // Data for CryptoKitties
  const cryptoKittiesData = {
    name: "CryptoKitties",
    logo: CryptoKittiesLogo,
    link: "https://www.cryptokitties.co/",
    background: "bg-rainbow-bands", // Custom background class from tailwind.config.js
    Official: [
      {
        name: "Instagram",
        link: "https://www.instagram.com/CryptoKitties/",
        icon: <FaInstagram />,
      },
      {
        name: "X",
        link: "https://x.com/CryptoKitties",
        icon: <FaTwitter />,
      },
      {
        name: "Discord",
        link: "https://discord.gg/cryptokitties",
        icon: <FaDiscord />,
      },
      {
        name: "Reddit",
        link: "https://www.reddit.com/r/CryptoKitties/",
        icon: <FaReddit />,
      },
      {
        name: "Telegram",
        link: "https://t.me/cryptokitties",
        icon: <FaTelegram />,
      },
    ],
  };

  // Adjust activeTab calculation
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const activeTab = pathSegments[1] || "cryptokitties-explorer";

  const handleTabChange = (tab) => {
    navigate(`/cryptokitties/${tab}`);
  };

  return (
    <div className="p-2">
      {/* Banner with logo, link, and social links */}
      <div
        className={`flex flex-col lg:flex-row items-center ${cryptoKittiesData.background} p-6 rounded-lg shadow-lg`}
      >
        <a
          href={cryptoKittiesData.link}
          target="_blank"
          rel="noopener noreferrer"
          className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
        >
          <img
            src={cryptoKittiesData.logo}
            alt={`${cryptoKittiesData.name} Logo`}
            className="h-20" // Logo height set to match Pinnacle banner height
          />
        </a>
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
          {cryptoKittiesData.Official.map((social) => (
            <a
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
            >
              {social.icon}
              <span className="ml-2 hidden lg:inline">{social.name}</span>
            </a>
          ))}
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex space-x-4 mt-4 mb-8 border-b-2 pb-2">
        <button
          onClick={() => handleTabChange("cryptokitties-explorer")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "cryptokitties-explorer"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          CryptoKitties Explorer
        </button>

        <button
          onClick={() => handleTabChange("allthezen")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "allthezen"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          AllTheZen
        </button>

        <button
          onClick={() => handleTabChange("analytics")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "analytics"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Analytics
        </button>
      </div>

      {/* Content Based on Active Tab */}
      {activeTab === "cryptokitties-explorer" && <CryptoKittiesExplorer />}
      {activeTab === "allthezen" && <AllTheZen />}
      {activeTab === "analytics" && <CryptoKittiesAnalytics />}
    </div>
  );
};

export default CryptoKitties;
