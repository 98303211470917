import React from "react";
import CryptoKittiesEggRarities from "./charts/CryptoKittiesEggRarities";
import CryptoKittiesEggSets from "./charts/CryptoKittiesEggSets";

const CryptoKittiesAnalytics = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">
        CryptoKitties: Egg Collection Analytics
      </h2>

      {/* Render the CryptoKittiesEggsRarities component */}
      <CryptoKittiesEggRarities />

      {/* Render the CryptoKittiesEggsSets component */}
      <CryptoKittiesEggSets />
    </div>
  );
};

export default CryptoKittiesAnalytics;
