// src/components/Events.js

import React from "react";
import CalendarComponent from "./CalendarComponent"; // Assuming CalendarComponent is in the same folder

const Events = () => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      {/* Display the CalendarComponent */}
      <CalendarComponent />
    </div>
  );
};

export default Events;
