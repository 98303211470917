// Community Logos
import NBA_TopShotLogo from "../logos/topshot.svg";
import NFL_ADLogo from "../logos/nflad.png";
import FlowLogo from "../logos/flow.svg";
import FlowCommunitiesLogo from "../logos/FlowCommunities.svg";
import FlowtyLogo from "../logos/flowty.svg";
import FlowverseLogo from "../logos/flowverse.png";
import CollectinConnectinLogo from "../logos/collectinconnectin.png";
import TopShotBetaLogo from "../logos/TopShotBeta.png";
import PetJokicsHorsesLogo from "../logos/PetJokicsHorses.png";
import NineLivesLoungeLogo from "../logos/NLL.png";
import IncrementFILogo from "../logos/IncrementFI.png";
import FindLabsLogo from "../logos/FindLabs.png";
import EmeraldCityLogo from "../logos/EmeraldCity.png";
import FlowConnectLogo from "../logos/FlowConnect.png";
import DisneyPinnacleLogo from "../logos/DisneyPinnacle.png";
import LiveTokenLogo from "../logos/LiveToken.png";
import DiamondLogo from "../logos/Diamond.png";
import CryptoKittiesLogo from "../logos/CryptoKitties.png";
import BadgeCountyLogo from "../logos/BadgeCounty.png";
import TomeSocietyLogo from "../logos/TomeSociety.png";
import PlayMFLLogo from "../logos/PlayMFL.png";
import BallerzLogo from "../logos/Ballerz.png";
import AFLMintLogo from "../logos/AFLMint.png";
import FanCrazeLogo from "../logos/FanCraze.png";
import DriverzLogo from "../logos/Driverz.png";
import MastersofMMALogo from "../logos/MastersofMMA.png";
import UFCStrikeLogo from "../logos/UFCStrike.png";
import aiSportsLogo from "../logos/aiSports.png";
import ABDCollectiblesLogo from "../logos/ABDCollectibles.png";
import KittyPunchLogo from "../logos/KittyPunch.png";
import OTMLogo from "../logos/OTM.png";
import OTMRumbleLogo from "../logos/OTMRumble.png";
import FastBreakLogo from "../logos/FastBreak.svg";
import WENPLogo from "../logos/WENP.png";

// Fan Communities Logos
import TSDGardenLogo from "../logos/TSDGarden.png";
import MohawksAtlantaNationLogo from "../logos/MohawksAtlantaNation.png";
import JurassicPackLogo from "../logos/JurassicPack.png";
import BonesCollectorsLogo from "../logos/BonesCollectors.png";
import HouseofHerbLogo from "../logos/HouseofHerb.png";
import MSGLELogo from "../logos/MSGLE.png";
import NothinButNetsLogo from "../logos/NothinButNets.png";
import TheHiveLogo from "../logos/TheHive.png";
import ChicagoPullsLogo from "../logos/ChicagoPulls.png";
import PackaliersLogo from "../logos/Packaliers.png";
import MaveripsLogo from "../logos/Maverips.png";
import BlockchainBadBoysLogo from "../logos/BlockchainBadBoys.png";
import StrengthInMomentsLogo from "../logos/StrengthInMoments.png";
import SpaceCityCentralLogo from "../logos/SpaceCityCentral.png";
import BoomBabyLogo from "../logos/BoomBaby.png";
import YachtClubLogo from "../logos/YachtClub.png";
import ShowtimeLogo from "../logos/Showtime.png";
import GrzGngLogo from "../logos/GrzGng.png";
import PackRileyMafiaLogo from "../logos/PackRileyMafia.png";
import CreamCityCollectorsLogo from "../logos/CreamCityCollectors.png";
import PacksAndPinstripesLogo from "../logos/PacksAndPinstripes.png";
import WolvesDenLogo from "../logos/WolvesDen.png";
import LoudCityCollectiveLogo from "../logos/LoudCityCollective.png";
import TrustTheMomentLogo from "../logos/TrustTheMoment.png";
import PHXPAXLogo from "../logos/PHXPAX.png";
import RipPacksCityLogo from "../logos/RipPacksCity.png";
import TopShotKingdomLogo from "../logos/TopShotKingdom.png";
import TopShotSpursLogo from "../logos/TopShotSpurs.png";
import SaltCityStackersLogo from "../logos/SaltCityStackers.png";
import DistrictOfCollectorsLogo from "../logos/DistrictOfCollectors.png";

// NBA Logos
import RaptorsLogo from "../logos/Raptors.png";
import HawksLogo from "../logos/Hawks.png";
import CelticsLogo from "../logos/Celtics.png";
import NetsLogo from "../logos/Nets.png";
import HornetsLogo from "../logos/Hornets.png";
import BullsLogo from "../logos/Bulls.png";
import CavaliersLogo from "../logos/Cavaliers.png";
import MavericksLogo from "../logos/Mavericks.png";
import NuggetsLogo from "../logos/Nuggets.png";
import PistonsLogo from "../logos/Pistons.png";
import WarriorsLogo from "../logos/Warriors.png";
import RocketsLogo from "../logos/Rockets.png";
import PacersLogo from "../logos/Pacers.png";
import ClippersLogo from "../logos/Clippers.png";
import LakersLogo from "../logos/Lakers.png";
import GrizzliesLogo from "../logos/Grizzlies.png";
import HeatLogo from "../logos/Heat.png";
import BucksLogo from "../logos/Bucks.png";
import TimberwolvesLogo from "../logos/Timberwolves.png";
import PelicansLogo from "../logos/Pelicans.png";
import KnicksLogo from "../logos/Knicks.png";
import ThunderLogo from "../logos/Thunder.png";
import MagicLogo from "../logos/Magic.png";
import SixersLogo from "../logos/Sixers.png";
import SunsLogo from "../logos/Suns.png";
import BlazersLogo from "../logos/Blazers.png";
import KingsLogo from "../logos/Kings.png";
import SpursLogo from "../logos/Spurs.png";
import JazzLogo from "../logos/Jazz.png";
import WizardsLogo from "../logos/Wizards.png";

import {
  FaDiscord,
  FaTwitter,
  FaLink,
  FaInstagram,
  FaFacebook,
  FaReddit,
  FaYoutube,
  FaTwitch,
  FaTiktok,
  FaTelegram,
} from "react-icons/fa"; // Ensure all icons are imported

const communities = {
  Communities: {
    subcategories: {
      "Digital Collectibles": [
        {
          name: "NFL All Day",
          logo: NFL_ADLogo,
          link: "https://nflallday.com",
          background: "bg-gradient-to-r from-gray-500 to-gray-800",
          Official: [
            {
              name: "Instagram",
              link: "https://www.instagram.com/nflallday/",
              icon: <FaInstagram />,
            },
            {
              name: "X",
              link: "https://twitter.com/NFLAllDay",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/invite/5K6qyTzj2k",
              icon: <FaDiscord />,
            },
            {
              name: "Reddit",
              link: "https://www.reddit.com/r/nflallday/",
              icon: <FaReddit />,
            },
          ],
        },
        {
          name: "UFC Strike",
          logo: UFCStrikeLogo,
          link: "https://ufcstrike.com/",
          background: "bg-gradient-to-r from-gray-500 to-gray-800",
          Official: [
            {
              name: "Instagram",
              link: "https://www.instagram.com/ufcstrike/",
              icon: <FaInstagram />,
            },
            {
              name: "X",
              link: "https://x.com/UFCStrike",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/ufcstrike",
              icon: <FaDiscord />,
            },
            {
              name: "Reddit",
              link: "https://www.reddit.com/r/ufcstrike/",
              icon: <FaReddit />,
            },
          ],
        },

        {
          name: "FanCraze",
          logo: FanCrazeLogo,
          link: "https://www.fancraze.com/",
          background: "bg-gradient-to-r from-blue-500 to-green-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/0xFanCraze",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/MnnwpHhaym",
              icon: <FaDiscord />,
            },
            {
              name: "Instagram",
              link: "https://instagram.com/0xfancraze",
              icon: <FaInstagram />,
            },
          ],
        },
        {
          name: "PlayMFL",
          logo: PlayMFLLogo,
          link: "https://app.playmfl.com/",
          background: "bg-gradient-to-r from-blue-500 to-green-300",
          Official: [
            {
              name: "X",
              link: "https://twitter.com/playMFL/",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/playmfl/",
              icon: <FaDiscord />,
            },
          ],
        },
        {
          name: "BALLERZ",
          logo: BallerzLogo,
          link: "https://BALLERZ.com/",
          background: "bg-gradient-to-r from-blue-500 to-green-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/BALLERZ_NFT",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/ballerznft",
              icon: <FaDiscord />,
            },
          ],
        },
        {
          name: "Driverz",
          logo: DriverzLogo,
          link: "https://driverz.world/",
          background: "bg-gradient-to-r from-blue-500 to-green-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/DriverzWorld",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/driverz",
              icon: <FaDiscord />,
            },
          ],
        },
        {
          name: "AFL Mint",
          logo: AFLMintLogo,
          link: "https://www.aflmint.com.au/",
          background: "bg-gradient-to-r from-blue-500 to-green-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/AFLMint",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://t.co/0WSTi8DhRU",
              icon: <FaDiscord />,
            },
          ],
        },
        {
          name: "ABD Collectibles",
          logo: ABDCollectiblesLogo,
          link: "https://abdcollectibles.com/",
          background: "bg-gradient-to-r from-blue-500 to-green-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/abdcollectibles",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/invite/qx374st6jM",
              icon: <FaDiscord />,
            },
            {
              name: "Instagram",
              link: "https://www.instagram.com/abdcollectibles/?hl=en",
              icon: <FaInstagram />,
            },
            {
              name: "Twitch",
              link: "https://twitch.tv/abdcollectibles/",
              icon: <FaTwitch />,
            },
            {
              name: "YouTube",
              link: "https://www.youtube.com/channel/UCJNlu09qOr7bGnwl4JWAx8g",
              icon: <FaYoutube />,
            },
          ],
        },
      ],

      "TopShot Communities": [
        {
          name: "Badge County",
          logo: BadgeCountyLogo,
          link: "https://x.com/Badge_County",
          background: "bg-gradient-to-r from-blue-300 to-gray-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/Badge_County",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://t.co/kM9Xm9lDQd",
              icon: <FaDiscord />,
            },
          ],
        },
        {
          name: "Collectin & Connectin",
          logo: CollectinConnectinLogo,
          link: "https://x.com/collctn_connctn",
          background: "bg-gradient-to-r from-green-600 to-yellow-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/collctn_connctn",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/P8j6th4c",
              icon: <FaDiscord />,
            },
            {
              name: "Shop",
              link: "https://shop.collectinandconnectin.com",
              icon: <FaLink />,
            },
            {
              name: "YouTube",
              link: "https://www.youtube.com/@collectinandconnectin",
              icon: <FaYoutube />,
            },
          ],
        },
        {
          name: "LiveToken",
          logo: LiveTokenLogo,
          link: "https://livetoken.co/",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-blue-600 to-red-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/LiveTokenCo",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.gg/kAkNr4FqrP",
              icon: <FaDiscord />,
            },
          ],
        },
        {
          name: "Nine Lives Lounge",
          logo: NineLivesLoungeLogo,
          link: "https://nll.nbatopshot.com/",
          background: "bg-gradient-to-r from-purple-600 to-blue-500",
          logoBackground: "bg-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/NineLivesLounge",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "Pet Jokics Horses",
          logo: PetJokicsHorsesLogo,
          link: "https://x.com/petjokicshorses",
          background: "bg-gradient-to-r from-red-600 to-yellow-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/3p3ff9PHqW",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/petjokicshorses",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "Tome Society",
          logo: TomeSocietyLogo,
          link: "https://www.tomesociety.com/fastbreak",
          background: "bg-gradient-to-r from-green-400 to-green-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/thetomesociety",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "TopShot Beta",
          logo: TopShotBetaLogo,
          link: "https://topshotbeta.com",
          background: "bg-gradient-to-r from-gray-600 to-blue-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/invite/vwYdbbF9VT",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/TopShotBETA",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "Own the Moment",
          logo: OTMLogo,
          link: "https://www.otmnft.com/nbatopshot",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/SmPFjE4apx",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/OwnTheMomentNFT",
              icon: <FaTwitter />,
            },
            {
              name: "YouTube",
              link: "https://www.youtube.com/channel/UC5syE_JaQmrChzJ66CzwYKw",
              icon: <FaYoutube />,
            },
          ],
        },
        {
          name: "$WENP WEN PACKS",
          logo: WENPLogo,
          link: "https://wenpacks.co/token",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/invite/dt6ZCZ8fK2",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/wenptoken",
              icon: <FaTwitter />,
            },
            {
              name: "Telegram",
              link: "https://t.me/wenptoken",
              icon: <FaTelegram />,
            },
          ],
        },
      ],

      "TopShot Fan Communities": [
        {
          name: "MoHawks Atlanta Nation",
          logo: MohawksAtlantaNationLogo,
          teamLogo: HawksLogo,
          link: "https://x.com/MoHawksAtlanta",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-yellow-400",
          Official: [
            {
              name: "X",
              link: "https://x.com/MoHawksAtlanta",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913670073695211560",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/atlanta-hawks-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "TSD Garden",
          logo: TSDGardenLogo,
          teamLogo: CelticsLogo,
          link: "https://x.com/TSDGarden",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-green-700 to-yellow-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/TSDGarden",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913672131102007307",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/boston-celtics-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Nothin' But Nets",
          logo: NothinButNetsLogo,
          teamLogo: NetsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/brooklyn-nets-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-black to-gray-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913670566156861471",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/brooklyn-nets-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "The Hive",
          logo: TheHiveLogo,
          teamLogo: HornetsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/charlotte-hornets-fan-community",
          logoBackground: "bg-gray-400",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-purple-600 to-teal-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/TheHiveTS",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913674265692356658",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/charlotte-hornets-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Chicago Pulls",
          logo: ChicagoPullsLogo,
          teamLogo: BullsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/chicago-bulls-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/chicagopulls",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913674762943885322",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/chicago-bulls-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Packaliers",
          logo: PackaliersLogo,
          teamLogo: CavaliersLogo,
          link: "https://about.nbatopshot.com/community-team-captains/cleveland-cavaliers-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-800 to-yellow-200",
          Official: [
            {
              name: "X",
              link: "https://x.com/packaliers",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913674888064139275",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/cleveland-cavaliers-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Maverips",
          logo: MaveripsLogo,
          teamLogo: MavericksLogo,
          link: "https://about.nbatopshot.com/community-team-captains/dallas-mavericks-fan-community-copy",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-500 to-gray-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/DallasMaverips",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913672791021215795",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/dallas-mavericks-fan-community-copy",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Bones Collectors",
          logo: BonesCollectorsLogo,
          teamLogo: NuggetsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/denver-nuggets-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-900 to-yellow-400",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913670725569773578",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/denver-nuggets-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Blockchain Bad Boys",
          logo: BlockchainBadBoysLogo,
          teamLogo: PistonsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/detroit-pistons-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-red-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/Blockchain_BBs",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913674459087523890",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/detroit-pistons-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Strength In Moments",
          logo: StrengthInMomentsLogo,
          teamLogo: WarriorsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/golden-state-warriors-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-900 to-yellow-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/StrengthMoments",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913673684328251432",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/golden-state-warriors-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Space City Central",
          logo: SpaceCityCentralLogo,
          teamLogo: RocketsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/houston-rockets-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/SccTopshot",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913676303239106560",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/houston-rockets-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Boom Baby",
          logo: BoomBabyLogo,
          teamLogo: PacersLogo,
          link: "https://about.nbatopshot.com/community-team-captains/indiana-pacers-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-gold-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/BoomBabyTS",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913674626293432351",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/indiana-pacers-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "310 Yacht Club",
          logo: YachtClubLogo,
          teamLogo: ClippersLogo,
          link: "https://about.nbatopshot.com/community-team-captains/los-angeles-clippers-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-red-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/310YachtClub",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913670318801977364",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/los-angeles-clippers-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Showtime",
          logo: ShowtimeLogo,
          teamLogo: LakersLogo,
          link: "https://about.nbatopshot.com/community-team-captains/los-angeles-lakers-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-purple-600 to-gold-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/LA__Showtime",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913671830705963038",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/los-angeles-lakers-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "GrzGng",
          logo: GrzGngLogo,
          teamLogo: GrizzliesLogo,
          link: "https://about.nbatopshot.com/community-team-captains/memphis-grizzlies-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/GrzGng",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913673436524580864",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/memphis-grizzlies-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Pack Riley Mafia",
          logo: PackRileyMafiaLogo,
          teamLogo: HeatLogo,
          link: "https://about.nbatopshot.com/community-team-captains/miami-heat-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-orange-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/PackRileyMafia",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913672268108931162",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/miami-heat-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Cream City Collectors",
          logo: CreamCityCollectorsLogo,
          teamLogo: BucksLogo,
          link: "https://about.nbatopshot.com/community-team-captains/milwaukee-bucks-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-green-600 to-yellow-200",
          Official: [
            {
              name: "X",
              link: "https://x.com/BucksTopshot",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913664389574434877",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/milwaukee-bucks-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Wolves Den",
          logo: WolvesDenLogo,
          teamLogo: TimberwolvesLogo,
          link: "https://about.nbatopshot.com/community-team-captains/minnesota-timberwolves-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-gray-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/WolvesDenTS",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913676011474935829",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/minnesota-timberwolves-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "House of Herb",
          logo: HouseofHerbLogo,
          teamLogo: PelicansLogo,
          link: "https://about.nbatopshot.com/community-team-captains/new-orleans-pelicans-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-teal-600 to-red-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/HouseOfHerb5",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913675146689126401",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/new-orleans-pelicans-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "MSGLE",
          logo: MSGLELogo,
          teamLogo: KnicksLogo,
          link: "https://about.nbatopshot.com/community-team-captains/new-york-knicks-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-orange-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/NYKnicksMSGLE",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913671992144695346",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/new-york-knicks-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Loud City Collective",
          logo: LoudCityCollectiveLogo,
          teamLogo: ThunderLogo,
          link: "https://about.nbatopshot.com/community-team-captains/oklahoma-city-thunder-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-orange-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/LoudCityCollect",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913676125736149004",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/oklahoma-city-thunder-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Packs & Pinstripes",
          logo: PacksAndPinstripesLogo,
          teamLogo: MagicLogo,
          link: "https://about.nbatopshot.com/community-team-captains/orlando-magic-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-blue-600 to-gray-300",
          Official: [
            {
              name: "X",
              link: "https://x.com/PacksPinstripes",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913674080434159637",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/orlando-magic-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Trust The Moment",
          logo: TrustTheMomentLogo,
          teamLogo: SixersLogo,
          link: "https://about.nbatopshot.com/community-team-captains/philadelphia-76ers-fan-community",
          teamLogoBackground: "bg-blue-500",
          background: "bg-gradient-to-r from-blue-800 to-red-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/TrustTheMoment",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913670950946484284",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/philadelphia-76ers-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "PHX PAX",
          logo: PHXPAXLogo,
          teamLogo: SunsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/phoenix-suns-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-purple-600 to-orange-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/phxpaxTS",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913665682074374154",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/phoenix-suns-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Rip Packs City",
          logo: RipPacksCityLogo,
          teamLogo: BlazersLogo,
          link: "https://about.nbatopshot.com/community-team-captains/portland-trail-blazers-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/rippackscity",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913672480080674916",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/portland-trail-blazers-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "TopShot Kingdom",
          logo: TopShotKingdomLogo,
          teamLogo: KingsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/sacramento-kings-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-purple-600 to-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/TopShotKingdom",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913675298082533407",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/sacramento-kings-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Top Shot Spurs",
          logo: TopShotSpursLogo,
          teamLogo: SpursLogo,
          link: "https://about.nbatopshot.com/community-team-captains/san-antonio-spurs-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-black to-gray-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/TopshotSpurs",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913675037230399488",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/san-antonio-spurs-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Jurassic Pack",
          logo: JurassicPackLogo,
          teamLogo: RaptorsLogo,
          link: "https://x.com/TheJurassicPack",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-black",
          Official: [
            {
              name: "X",
              link: "https://x.com/TheJurassicPack",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913673548864823307",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/toronto-raptors-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "Salt City Stackers",
          logo: SaltCityStackersLogo,
          teamLogo: JazzLogo,
          link: "https://about.nbatopshot.com/community-team-captains/utah-jazz-fan-community",
          teamLogoBackground: "bg-purple-500",
          background: "bg-gradient-to-r from-purple-600 to-blue-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/SaltCityStacks",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913671326680637462",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/utah-jazz-fan-community",
              icon: <FaLink />,
            },
          ],
        },
        {
          name: "District of Collectors",
          logo: DistrictOfCollectorsLogo,
          teamLogo: WizardsLogo,
          link: "https://about.nbatopshot.com/community-team-captains/washington-wizards-fan-community",
          teamLogoBackground: "bg-white",
          background: "bg-gradient-to-r from-red-600 to-blue-600",
          Official: [
            {
              name: "X",
              link: "https://x.com/DCTopShot",
              icon: <FaTwitter />,
            },
            {
              name: "Discord",
              link: "https://discord.com/channels/606111887876292622/913673860220592148",
              icon: <FaDiscord />,
            },
            {
              name: "Community Page",
              link: "https://about.nbatopshot.com/community-team-captains/washington-wizards-fan-community",
              icon: <FaLink />,
            },
          ],
        },
      ],

      "NFL AllDay Communities": [
        {
          name: "Own the Moment",
          logo: OTMLogo,
          link: "https://www.otmnft.com/nflallday",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/SmPFjE4apx",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/OwnTheMomentNFT",
              icon: <FaTwitter />,
            },
            {
              name: "YouTube",
              link: "https://www.youtube.com/channel/UC5syE_JaQmrChzJ66CzwYKw",
              icon: <FaYoutube />,
            },
          ],
        },
      ],

      "Ecosystem Hubs": [
        {
          name: "Flowverse",
          logo: FlowverseLogo,
          link: "https://www.flowverse.co/",
          background: "bg-gradient-to-r from-blue-300 to-blue-800",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/invite/Krvxfmv8Dy",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/flowverse_",
              icon: <FaTwitter />,
            },
          ],
        },
      ],
      Development: [
        {
          name: "Emerald City",
          logo: EmeraldCityLogo,
          link: "https://docs.ecdao.org/",
          background: "bg-gradient-to-r from-green-600 to-yellow-300",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/68ZH524d",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/emerald_dao",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "FindLabs",
          logo: FindLabsLogo,
          link: "https://www.findlabs.io/",
          background: "bg-gradient-to-r from-blue-600 to-pink-400",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/xXNF3ska",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/findlabs",
              icon: <FaTwitter />,
            },
          ],
        },
      ],
      Marketplaces: [
        {
          name: "Flowty",
          logo: FlowtyLogo,
          link: "https://www.flowty.io/",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/invite/flowty",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/flowty_io",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "Flowverse Marketplace",
          logo: FlowverseLogo,
          link: "https://nft.flowverse.co/marketplace",
          background: "bg-gradient-to-r from-blue-300 to-blue-800",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/invite/Krvxfmv8Dy",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/flowverse_",
              icon: <FaTwitter />,
            },
          ],
        },
      ],
      Flow: [
        {
          name: "Flow",
          logo: FlowLogo,
          link: "https://flow.com",
          background: "bg-gradient-to-r from-gray-500 to-gray-800",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/flowblockchain",
              icon: <FaDiscord />,
            },
            {
              name: "Reddit (r/flow - newly made)",
              link: "https://www.reddit.com/r/flow/",
              icon: <FaReddit />,
            },
            {
              name: "Reddit (r/FlowBlockchain - made earlier)",
              link: "https://www.reddit.com/r/FlowBlockchain/",
              icon: <FaReddit />,
            },
          ],
        },
      ],
      Games: [
        {
          name: "OTM Rumble",
          logo: OTMRumbleLogo,
          link: "https://www.otmnft.com/rumble/lobby",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/SmPFjE4apx",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/OwnTheMomentNFT",
              icon: <FaTwitter />,
            },
            {
              name: "YouTube",
              link: "https://www.youtube.com/channel/UC5syE_JaQmrChzJ66CzwYKw",
              icon: <FaYoutube />,
            },
          ],
        },
        {
          name: "Masters of MMA",
          logo: MastersofMMALogo,
          link: "https://mommagame.com/",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/X2qvCm4ufu",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://twitter.com/mastersofmma",
              icon: <FaTwitter />,
            },
            {
              name: "Instagram",
              link: "https://www.instagram.com/masters_of_mma/",
              icon: <FaInstagram />,
            },
          ],
        },
        {
          name: "aiSports",
          logo: aiSportsLogo,
          link: "https://www.aisportspro.com/",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.com/invite/VKvUxMwu5r",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/aisportspro",
              icon: <FaTwitter />,
            },
            {
              name: "Instagram",
              link: "https://www.instagram.com/aisportspro/",
              icon: <FaInstagram />,
            },
            {
              name: "TikTok",
              link: "https://www.tiktok.com/@ai_sports",
              icon: <FaTiktok />,
            },
          ],
        },
      ],
      Other: [
        {
          name: ".find",
          logo: FindLabsLogo,
          link: "https://find.xyz/",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/tCSFSpFcRv",
              icon: <FaDiscord />,
            },
          ],
        },
      ],
      DEFI: [
        {
          name: "IncrementFI",
          logo: IncrementFILogo,
          link: "https://app.increment.fi/",
          background: "bg-gradient-to-r from-gray-800 to-yellow-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/2GPnYRpB",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/IncrementFi",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "KittyPunch",
          logo: KittyPunchLogo,
          link: "https://app.kittypunch.xyz/",
          background: "bg-gradient-to-r from-gray-800 to-yellow-500",
          Official: [
            {
              name: "X",
              link: "https://x.com/KittyPunchXYZ",
              icon: <FaTwitter />,
            },
          ],
        },
      ],
      "Data and Analytics": [
        {
          name: "Diamond",
          logo: DiamondLogo,
          link: "https://linktr.ee/diamondflow",
          background: "bg-gradient-to-r from-gray-300 to-gray-800",
          Official: [
            {
              name: "X",
              link: "https://x.com/DiamondNFL",
              icon: <FaTwitter />,
            },
          ],
        },
        {
          name: "Own the Moment",
          logo: OTMLogo,
          link: "https://www.otmnft.com/nflallday",
          logoBackground: "bg-black",
          background: "bg-gradient-to-r from-purple-700 to-green-500",
          Official: [
            {
              name: "Discord",
              link: "https://discord.gg/SmPFjE4apx",
              icon: <FaDiscord />,
            },
            {
              name: "X",
              link: "https://x.com/OwnTheMomentNFT",
              icon: <FaTwitter />,
            },
            {
              name: "YouTube",
              link: "https://www.youtube.com/channel/UC5syE_JaQmrChzJ66CzwYKw",
              icon: <FaYoutube />,
            },
          ],
        },
      ],
    },
  },
};

export default communities;
