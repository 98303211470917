import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import {
  fetchNewsFromApi, // Fetch all news regardless of page
  shortenText,
  capitalizeFirstLetter,
  getSourceLabel, // Import the utility function
} from "./RSSUtilities";

const News = () => {
  const [allFeedItems, setAllFeedItems] = useState([]); // All fetched items
  const [filteredItems, setFilteredItems] = useState([]); // Filtered and paginated items
  const [filteredItemsCount, setFilteredItemsCount] = useState(0); // Total count of filtered items
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [sortByDate, setSortByDate] = useState("desc");
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const [currentPage, setCurrentPage] = useState(1); // Pagination
  const itemsPerPage = 10; // Show 10 items per page

  useEffect(() => {
    const fetchAllNews = async () => {
      try {
        setIsLoading(true); // Start loading
        console.log("Fetching all news from API...");
        const data = await fetchNewsFromApi(); // Fetch all news without limit
        console.log("Fetched data from API:", data);

        if (data && data.items && data.items.length > 0) {
          // Apply the label mapping to each item
          const mappedItems = data.items.map((item) => ({
            ...item,
            source: getSourceLabel(item.source, item.url), // Apply label mapping
          }));

          setAllFeedItems(mappedItems); // Set all fetched items with mapped labels
          setFilteredItems(mappedItems); // Initially, filtered items are the same
          setFilteredItemsCount(mappedItems.length); // Set the total number of filtered items
        } else {
          console.warn("No items found in the response.");
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };
    fetchAllNews();
  }, []);

  // Filter and sort items whenever search term, labels, or feed items change
  useEffect(() => {
    console.log("Filtering and sorting feed items...");

    let filtered = allFeedItems.filter((item) => {
      const title = item.title || "";
      const content = item.description || "";

      const matchesSearch =
        searchTerm === "" ||
        title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        content.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesLabel =
        selectedLabels.length === 0 || selectedLabels.includes(item.source);

      return matchesSearch && matchesLabel;
    });

    // Update the total count of filtered items before pagination
    setFilteredItemsCount(filtered.length);

    // Sort the filtered items
    filtered = filtered.sort((a, b) => {
      const dateA = new Date(a.publishedDate || a.pubDate);
      const dateB = new Date(b.publishedDate || b.pubDate);
      return sortByDate === "asc" ? dateA - dateB : dateB - dateA;
    });

    // Paginate the filtered results
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = filtered.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    setFilteredItems(paginatedItems);
  }, [searchTerm, selectedLabels, allFeedItems, sortByDate, currentPage]);

  const handleDescription = (htmlContent, textContent) => {
    if (htmlContent) {
      const strippedText = DOMPurify.sanitize(htmlContent, {
        ALLOWED_TAGS: [],
      });
      const shortenedText = shortenText(strippedText, 100);
      return shortenedText === "No description available."
        ? ""
        : capitalizeFirstLetter(shortenedText);
    } else if (textContent) {
      const shortenedText = shortenText(textContent, 100);
      return shortenedText === "No description available."
        ? ""
        : capitalizeFirstLetter(shortenedText);
    }

    // Return an empty string if no description is available
    return "";
  };

  const toggleLabelFilter = (label) => {
    setSelectedLabels((prev) =>
      prev.includes(label) ? prev.filter((l) => l !== label) : [...prev, label]
    );
    setCurrentPage(1); // Reset to the first page when applying a filter
  };

  const handleSearch = () => {
    setSearchTerm(searchInput);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const handleClearFilters = () => {
    setSelectedLabels([]);
    setSearchInput("");
    setSearchTerm("");
    setCurrentPage(1); // Reset to the first page when clearing filters
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Determine total pages for the filtered results
  const totalPages = Math.ceil(filteredItemsCount / itemsPerPage);

  return (
    <div className="max-w-7xl mx-auto p-4">
      {/* Show loading spinner or text */}
      {isLoading && (
        <div className="flex justify-center items-center mb-4">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="ml-2">Loading news...</p>
        </div>
      )}

      {/* Search, Sorting, and Filters */}
      {!isLoading && (
        <>
          <div className="mb-4 flex flex-col md:flex-row justify-between">
            {/* Search Bar */}
            <div className="flex items-center space-x-2">
              <input
                type="text"
                placeholder="Search news..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") handleSearch();
                }}
                className="border p-2 rounded-md w-full md:w-[70%] mb-2 md:mb-0"
              />
              <button
                onClick={handleSearch}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Search
              </button>
            </div>

            {/* Sorting Dropdown */}
            <div className="flex space-x-4 items-center">
              <label className="font-semibold">Sort by:</label>
              <select
                value={sortByDate}
                onChange={(e) => setSortByDate(e.target.value)}
                className="border p-2 rounded-md"
              >
                <option value="desc">Newest First</option>
                <option value="asc">Oldest First</option>
              </select>
            </div>
          </div>

          {/* Filter by Source - Collapsible */}
          <div className="mb-4">
            <details className="bg-gray-200 p-4 rounded-md shadow-sm">
              <summary className="cursor-pointer font-semibold text-gray-700">
                Filter by Source
              </summary>
              <div className="flex flex-wrap mt-4">
                {allFeedItems.length > 0 ? (
                  Array.from(
                    new Set(allFeedItems.map((item) => item.source))
                  ).map((label) => (
                    <label key={label} className="mr-4 mb-2">
                      <input
                        type="checkbox"
                        checked={selectedLabels.includes(label)}
                        onChange={() => toggleLabelFilter(label)}
                        className="mr-2"
                      />
                      <span
                        className={`inline-block px-3 py-1 rounded-md text-sm ${
                          selectedLabels.includes(label)
                            ? "bg-blue-500 text-white"
                            : "bg-gray-300 text-gray-700"
                        }`}
                      >
                        {label}
                      </span>
                    </label>
                  ))
                ) : (
                  <p>No sources available.</p>
                )}
              </div>
              <button
                onClick={handleClearFilters}
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Clear Filters
              </button>
            </details>
          </div>

          {/* Show the number of filtered results */}
          <div className="mb-4">
            <p className="text-gray-700">{filteredItemsCount} items found</p>
          </div>

          {/* News Feed */}
          {filteredItems.length > 0 ? (
            <ul className="space-y-4">
              {filteredItems.map((item) => (
                <li
                  key={item.uniqueId}
                  className="bg-white shadow-lg rounded-md border border-gray-200 hover:shadow-xl transition-all duration-300 p-4 flex flex-col lg:flex-row lg:items-start"
                >
                  {item.image && (
                    <a
                      href={item.url || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition-transform duration-300 hover:scale-105 flex-shrink-0 w-full lg:w-1/3 lg:mr-4"
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-full h-48 object-cover rounded-md"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://via.placeholder.com/150?text=No+Image";
                        }}
                      />
                    </a>
                  )}
                  <div className="lg:w-2/3 flex flex-col justify-between">
                    <div>
                      <p className="text-sm text-gray-500">{item.source}</p>
                      <a
                        href={item.url || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block text-lg font-semibold text-blue-600 transition-transform duration-300 hover:scale-105 hover:underline"
                      >
                        {item.title || "No title available"}
                      </a>
                      <p className="text-gray-500 text-sm mt-1">
                        {item.publishedDate
                          ? new Date(item.publishedDate).toLocaleString()
                          : "No date available"}
                      </p>

                      <div className="mt-1 text-sm text-gray-700">
                        <p>
                          {handleDescription(
                            item.description,
                            item.content_text
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-center text-gray-500">No news available.</p>
          )}

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="flex justify-center space-x-4 mt-4">
              {currentPage > 1 && (
                <button
                  onClick={handlePrevPage}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Previous
                </button>
              )}
              {currentPage < totalPages && (
                <button
                  onClick={handleNextPage}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Next
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default News;
