import React from "react";
import { useNavigate } from "react-router-dom";

// Helper function to track clicks
const trackClick = (category, label) => {
  if (window.gtag) {
    window.gtag("event", "click", {
      event_category: category,
      event_label: label,
    });
  }
};

const ProjectExplorer = () => {
  const navigate = useNavigate();

  const projects = [
    {
      name: "Flow",
      logo: "https://storage.googleapis.com/flowconnect/logos/Flow2.svg",
      link: "/flow",
      bgGradient: "from-pink-300 via-blue-300 to-teal-300",
      logoWidth: "w-56 h-40 object-contain", // Slightly bigger size for Flow logo
    },
    {
      name: "NBA TopShot",
      logo: "https://storage.googleapis.com/flowconnect/logos/TopShot-white.svg",
      link: "/topshot",
      bgGradient: "from-blue-400 via-gray-400 to-red-400",
      logoWidth: "w-64",
    },
    {
      name: "NFL AllDay",
      logo: "https://assets.nflallday.com/static/images/logos/nfl-all-day.svg",
      link: "/allday",
      bgGradient: "bg-gradient-to-r from-gray-500 via-gray-300 to-gray-500",
      logoWidth: "w-64",
    },
    {
      name: "Disney Pinnacle",
      logo: "https://storage.googleapis.com/flowconnect/logos/Pinnacle.png",
      link: "/pinnacle",
      bgGradient: "from-purple-600 via-blue-600 to-pink-600",
      logoWidth: "w-64",
    },
    {
      name: "CryptoKitties",
      logo: "https://storage.googleapis.com/flowconnect/logos/cryptokitties.svg",
      link: "/cryptokitties",
      bgGradient: "bg-rainbow-bands",
      logoSize: "w-28 h-28 rounded-full object-contain", // Increased size
      textBackground: "bg-pink-100 rounded-full p-2",
      textColor:
        "text-gray-900 font-medium text-[20px] leading-[20px] tracking-[-0.3px]",
    },
  ];

  return (
    <div className="space-y-4 pt-4">
      {/* Instructions for Project Explorer */}
      <div className="bg-white p-4 rounded-md shadow-md mb-4">
        <h2 className="text-xl font-bold text-gray-800">Project Explorer</h2>
        <p className="text-gray-600">
          Choose a project below to explore its content.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`flex items-center justify-center bg-gradient-to-r ${project.bgGradient} p-6 rounded-lg shadow-lg h-32`}
          >
            {project.name === "CryptoKitties" ? (
              <button
                onClick={() => navigate(project.link)}
                className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg flex items-center"
              >
                <img
                  src={project.logo}
                  alt={`${project.name} Logo`}
                  className={project.logoSize}
                />
                <div className={project.textBackground + " ml-4"}>
                  <span className={project.textColor}>{project.name}</span>
                </div>
              </button>
            ) : (
              <a
                href={project.link}
                className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
                onClick={() => trackClick("Project", project.name)}
              >
                <img
                  src={project.logo}
                  alt={`${project.name} Logo`}
                  className={project.logoWidth}
                />
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectExplorer;
