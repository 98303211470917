import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";

// Register necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PinnacleCollectors = () => {
  const [chartDataCollectorsTraders, setChartDataCollectorsTraders] = useState(
    {}
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://flipsidecrypto.xyz/api/v1/queries/72bf7d71-870d-4ea1-b428-91e18cc7c508/data/latest"
      );
      const data = await response.json();

      const eventDays = data.map((item) =>
        dayjs(item.EVENT_DAY).format("YYYY-MM-DD")
      );

      const totalTrades = data.map((item) => item.RUNNING_TOTAL_TRADES);
      const totalCollectors = data.map((item) => item.RUNNING_TOTAL_COLLECTORS);
      const totalUniqueTraders = data.map(
        (item) => item.RUNNING_TOTAL_UNIQUE_TRADERS
      );

      setChartDataCollectorsTraders({
        labels: eventDays,
        datasets: [
          {
            label: "Running Total Trades",
            data: totalTrades,
            borderColor: "rgba(153, 102, 255, 1)",
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            fill: false,
            pointRadius: 0,
          },
          {
            label: "Running Total Collectors",
            data: totalCollectors,
            borderColor: "rgba(255, 159, 64, 1)",
            backgroundColor: "rgba(255, 159, 64, 0.2)",
            fill: false,
            pointRadius: 0,
          },
          {
            label: "Running Total Unique Traders",
            data: totalUniqueTraders,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: false,
            pointRadius: 0,
          },
        ],
      });
    };

    fetchData();
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">
          Pinnacle: Total Collectors and Traders Over Time
        </h3>
        {chartDataCollectorsTraders.labels ? (
          <Line
            data={chartDataCollectorsTraders}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false, // No title inside chart, handled outside
                },
                tooltip: {
                  enabled: true, // Show tooltip on hover
                },
                datalabels: {
                  display: false, // Disable data point labels
                },
              },
              scales: {
                x: {
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 12, // Limit the number of ticks displayed
                  },
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default PinnacleCollectors;
