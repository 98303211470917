import React from "react";
import AllDayOvertime from "./charts/AllDayOvertime"; // Import from the charts directory
import AllDayBuyersSellers from "./charts/AllDayBuyersSellers";
import AllDaySalesValue from "./charts/AllDaySalesValue";
import AllDayFloors from "./charts/AllDayFloors";

const AllDayAnalytics = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        {/* Render the AllDayOvertime chart */}
        <AllDayOvertime />
      </div>
      <div className="mb-6">
        {/* Render the AllDayBuyersSellers chart */}
        <AllDayBuyersSellers />
      </div>
      <div className="mb-6">
        {/* Render the AllDaySalesValue chart */}
        <AllDaySalesValue />
      </div>
      <div className="mb-6">
        {/* Render the AllDayFloors chart */}
        <AllDayFloors />
      </div>

      {/* Section: External Resource */}
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">
          Additional Resources for In-Depth NFL AllDay Analytics
        </h3>
        <p className="text-gray-700 mb-4">
          For more detailed analytics on NFL AllDay moments, players, and market
          trends, you can explore OTM NFT. This is a paid external resource that
          provides insights beyond the data shown here.
        </p>

        <a
          href="https://www.otmnft.com/nflallday"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-blue-500 underline hover:text-blue-700 transition-colors duration-300"
        >
          Explore OTM NFT Analytics
        </a>
      </div>
    </div>
  );
};

export default AllDayAnalytics;
