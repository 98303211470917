import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom"; // Import necessary hooks
import PinnacleLogo from "../logos/Pinnacle.png";
import fcl from "../config";
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";
import PinnacleSeries from "./PinnacleSeries";
import Timeline from "./Timeline";
import PinnacleAnalytics from "./PinnacleAnalytics";
import {
  getSetBackground,
  splitSetName,
  AboutSection,
  Footer,
  parseUnicode,
} from "./PinnacleHelpers";
import { loadSetImage } from "./GenericHelpers";

const Pinnacle = () => {
  const { seriesId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [setData, setSetData] = useState([]);
  const [series, setSeries] = useState([]);
  const [completedSetIDs, setCompletedSetIDs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({ seriesSummary: {}, totalSets: 0 });
  const [imageCache, setImageCache] = useState({});

  // Adjusted code starts here
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const activeTab = pathSegments[1] || "pin-explorer";
  // Adjusted code ends here

  useEffect(() => {
    fcl.currentUser().subscribe((user) => {
      setUser(user?.addr ? user : null);
    });
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [seriesRes, setsRes] = await Promise.all([
        fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-series"
        ).then((res) => res.json()),
        fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-sets"
        ).then((res) => res.json()),
      ]);
      setSeries(seriesRes || []);
      setSetData(setsRes || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cachedSetImages = useMemo(() => {
    const updatedImageCache = { ...imageCache };
    setData.forEach((set) => {
      if (!updatedImageCache[set.id]) {
        loadSetImage(set.id, "pinnacle").then((imageUrl) => {
          updatedImageCache[set.id] = imageUrl;
          setImageCache(updatedImageCache);
        });
      }
    });
    return updatedImageCache;
  }, [setData, imageCache]);

  const selectedSeries = seriesId ? parseInt(seriesId) : series[0]?.id || null;

  const handleTabChange = (tab) => {
    // Update URL and active tab
    navigate(`/pinnacle/${tab}`);
  };

  return (
    <div className="p-2">
      {/* Banner with logo and social links */}
      <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-purple-600 via-blue-600 to-pink-600 p-6 rounded-lg shadow-lg">
        <a
          href="https://disneypinnacle.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
        >
          <img src={PinnacleLogo} alt="Disney Pinnacle Logo" className="h-20" />
        </a>
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
          <a
            href="https://www.instagram.com/disneypinnacle/"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Instagram</span>
          </a>
          <a
            href="https://x.com/DisneyPinnacle"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">X</span>
          </a>
          <a
            href="https://discord.gg/disneypinnacle"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaDiscord className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Discord</span>
          </a>
          <a
            href="https://www.reddit.com/r/disneypinnacle/"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaReddit className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Reddit</span>
          </a>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex space-x-4 mt-4 mb-8 border-b-2 pb-2">
        <button
          onClick={() => handleTabChange("pin-explorer")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "pin-explorer"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Pin Explorer
        </button>
        <button
          onClick={() => handleTabChange("timeline")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "timeline"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Timeline
        </button>
        <button
          onClick={() => handleTabChange("analytics")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "analytics"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Analytics
        </button>
        <button
          onClick={() => handleTabChange("about")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "about"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          About
        </button>
      </div>

      {/* Content Based on Active Tab */}
      {(activeTab === "pin-explorer" || activeTab === "series") && (
        <PinnacleSeries
          series={series}
          selectedSeries={selectedSeries}
          setData={setData}
          cachedSetImages={cachedSetImages}
          completedSetIDs={completedSetIDs}
        />
      )}

      {activeTab === "timeline" && <Timeline defaultProject="Pinnacle" />}

      {activeTab === "analytics" && <PinnacleAnalytics />}

      {activeTab === "about" && <AboutSection />}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Pinnacle;
