import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import axios from "axios";
import DOMPurify from "dompurify";
import { getLogoUrl } from "./GenericHelpers"; // Import the helper function

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [activeSource, setActiveSource] = useState("All");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const calendarRef = useRef(null);
  const dropdownRef = useRef(null); // Ref for the dropdown

  const calendarSources = [
    {
      id: "08b1fcc2b88219beb6564097a5e97ed20d2ddf6263ae792a3772d7c5681f8c50@group.calendar.google.com",
      className: "topshot-active-challenges",
      label: "TopShot Active Challenges",
      logo: getLogoUrl("TopShotIcon"),
      color: "#ccccff",
    },
    {
      id: "d8195ace6087ccdf7d1de1f340852de0b13ef95342cb0b9cc4bcff13a842f0e8@group.calendar.google.com",
      className: "nba-games",
      label: "NBA Games",
      logo: getLogoUrl("NBAIcon"),
      color: "#6699ff",
    },
    {
      id: "f83c1fcb0f1a9e4900c43ad7ea7135797dfd352edba69c2869b241654463797c@group.calendar.google.com",
      className: "nfl-games",
      label: "NFL Games",
      logo: getLogoUrl("NFLIcon"),
      color: "#96b6ff",
    },
    {
      id: "betadabull@gmail.com",
      className: "beta-events",
      label: "$BETA",
      logo: getLogoUrl("TopShotBeta"),
      color: "#33ccff",
    },
    {
      id: "c_47978f5cd9da636cadc6b8473102b5092c1a865dd010558393ecb7f9fd0c9ad0@group.calendar.google.com",
      className: "flow-events",
      label: "Flow Events",
      logo: getLogoUrl("Flow"),
      color: "#32CD32",
    },
    {
      id: "petjokicshorses@proton.me",
      className: "pet-events",
      label: "Pet Jokic's Horses",
      logo: getLogoUrl("PetJokicsHorses"),
      color: "#FF69B4",
    },
  ];

  const getTimeRange = () => {
    const now = new Date();
    const timeMinDate = new Date(now);
    timeMinDate.setMonth(now.getMonth() - 1);
    const timeMaxDate = new Date(now);
    timeMaxDate.setMonth(now.getMonth() + 3);
    return {
      timeMin: timeMinDate.toISOString(),
      timeMax: timeMaxDate.toISOString(),
    };
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { timeMin, timeMax } = getTimeRange();
        const promises = calendarSources.map((calendar) =>
          axios.get(
            `https://flowconnectbackend-864654c6a577.herokuapp.com/api/calendar/events`,
            {
              params: {
                calendarId: calendar.id,
                timeMin,
                timeMax,
              },
            }
          )
        );
        const results = await Promise.all(promises);
        const allEvents = results.flatMap((result, index) =>
          result.data.map((event) => ({
            title: event.summary,
            start: event.start.dateTime || event.start.date,
            end: event.end?.dateTime || event.end?.date || null,
            description: event.description || "No description",
            url: event.htmlLink,
            className: calendarSources[index].className,
            backgroundColor: calendarSources[index].color,
            borderColor: calendarSources[index].color,
            textColor: "#fff",
            extendedProps: {
              source: calendarSources[index].label,
              logo: calendarSources[index].logo,
            },
          }))
        );

        // Filter multi-day events to only show start and end for TopShot Active Challenges
        const filteredEvents = allEvents.flatMap((event) => {
          if (
            event.extendedProps.source === "TopShot Active Challenges" &&
            event.start &&
            event.end &&
            new Date(event.start).toDateString() !==
              new Date(event.end).toDateString()
          ) {
            return [
              { ...event, title: `${event.title} (Start)`, end: event.start },
              { ...event, title: `${event.title} (End)`, start: event.end },
            ];
          }
          return [event];
        });

        setEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        const newView = window.innerWidth < 768 ? "dayGridDay" : "dayGridWeek";
        if (calendarApi.view.type !== newView) {
          calendarApi.changeView(newView);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEventClick = (info) => {
    info.jsEvent.preventDefault();
    setSelectedEvent({
      title: info.event.title || "No title",
      description: sanitizeDescription(info.event.extendedProps.description),
      start: info.event.start,
      end: info.event.end,
      source: info.event.extendedProps.source,
      logo: info.event.extendedProps.logo,
      url: info.event.url,
    });
  };

  const sanitizeDescription = (description) => {
    return DOMPurify.sanitize(description, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
    });
  };

  const filterEvents = (source) => {
    setActiveSource(source);
    setIsDropdownOpen(false);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const filteredEvents =
    activeSource === "All"
      ? events
      : events.filter((event) => event.extendedProps.source === activeSource);

  const renderEventContent = (eventInfo) => {
    const { source } = eventInfo.event.extendedProps;
    const sourceColor =
      calendarSources.find((calendar) => calendar.label === source)?.color ||
      "#000";

    // Default content for non-NBA and non-NFL sources, ensuring consistent height
    let content = (
      <div
        className="flex items-center"
        style={{
          backgroundColor: sourceColor,
          borderColor: sourceColor,
          color: "#fff",
          padding: "2px",
          borderRadius: "4px",
          height: "40px", // Set a fixed height for consistency
        }}
      >
        <img src={getLogoUrl(source)} alt={source} className="h-6 w-6 mr-2" />
        <span>
          {eventInfo.timeText} {eventInfo.event.title}
        </span>
      </div>
    );

    // Custom content for NBA and NFL games, displaying team logos
    if (source === "NBA Games" || source === "NFL Games") {
      const delimiter = source === "NBA Games" ? " @ " : " at ";
      const [team1, team2] = eventInfo.event.title
        .replace(/[^\w\s@]/g, "") // Remove emojis and special characters, but keep "@" for NBA
        .split(delimiter)
        .map((team) => team.trim());

      if (team1 && team2) {
        content = (
          <div
            className="flex items-center"
            style={{
              height: "40px", // Consistent height with other events
            }}
          >
            <span className="mr-2">{eventInfo.timeText}</span>
            <img src={getLogoUrl(team1)} alt={team1} className="h-8 w-8 mr-2" />
            <span className="mr-2">at</span>
            <img src={getLogoUrl(team2)} alt={team2} className="h-8 w-8 ml-2" />
          </div>
        );
      }
    }

    return content;
  };

  return (
    <div>
      <h2 className="text-2xl text-left font-bold text-gray-800 mb-2">
        Upcoming Events
      </h2>

      <div className="flex justify-center space-x-4 mb-6 relative">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="bg-gray-200 p-2 rounded flex items-center"
        >
          {activeSource !== "All" && (
            <img
              src={getLogoUrl(
                calendarSources.find((source) => source.label === activeSource)
                  ?.label
              )}
              alt={activeSource}
              className="h-4 w-4 mr-2"
            />
          )}
          {activeSource === "All" ? "All Events" : activeSource}
          <span className="ml-2">&#9662;</span>
        </button>
        {isDropdownOpen && (
          <ul
            ref={dropdownRef}
            className="absolute bg-white border border-gray-300 rounded shadow-lg mt-2 w-48 z-10"
          >
            <li
              onClick={() => filterEvents("All")}
              className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <span className="ml-2">All Events</span>
            </li>
            {calendarSources.map((source) => (
              <li
                key={source.id}
                onClick={() => filterEvents(source.label)}
                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
              >
                <img
                  src={getLogoUrl(source.label)} // Consistently use getLogoUrl for dropdown items
                  alt={source.label}
                  className="h-4 w-4 mr-2"
                />
                <span>{source.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView={isMobile ? "dayGridDay" : "dayGridWeek"}
        events={filteredEvents}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: isMobile ? "" : "dayGridMonth,dayGridWeek,dayGridDay",
        }}
        height="auto"
        eventDisplay="block"
      />

      {selectedEvent && (
        <div className="modal fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="modal-content bg-white p-4 rounded shadow-lg w-4/5 max-w-2xl">
            <div className="flex items-center">
              <img
                src={getLogoUrl(selectedEvent.source)}
                alt={selectedEvent.source}
                className="h-8 w-8 mr-2"
              />
              <h2 className="text-xl font-bold">{selectedEvent.title}</h2>
            </div>
            <p>
              <strong>Start:</strong>{" "}
              {selectedEvent.start
                ? new Date(selectedEvent.start).toLocaleString()
                : "No start time"}
            </p>
            {selectedEvent.end && (
              <p>
                <strong>End:</strong>{" "}
                {new Date(selectedEvent.end).toLocaleString()}
              </p>
            )}
            {selectedEvent.source !== "NBA Games" && (
              <div
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: selectedEvent.description }}
              />
            )}
            {selectedEvent.url && (
              <a
                href={selectedEvent.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-blue-500 hover:text-blue-700 mt-4"
              >
                View Event on Google Calendar
              </a>
            )}
            <button
              onClick={closeModal}
              className="mt-4 bg-red-500 text-white p-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarComponent;
