import React, { useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Timeline from "./components/Timeline";
import Footer from "./components/Footer";
import Communities from "./components/Communities";
import Events from "./components/Events";
import ProjectExplorer from "./components/ProjectExplorer";
import Collection from "./components/Collection";
import Pinnacle from "./components/Pinnacle";
import PinnacleSets from "./components/PinnacleSets";
import PinnacleEditions from "./components/PinnacleEditions";
import TopShot from "./components/TopShot";
import TopShotSets from "./components/TopShotSets";
import AllDay from "./components/AllDay";
import CryptoKitties from "./components/CryptoKitties";
import { UserProvider } from "./components/UserContext";
import Analytics from "./components/Analytics";
import News from "./components/News"; // No need to pass rssFeeds anymore

// Import the new Flow components
import Flow from "./components/Flow"; // Flow main component
import FlowAnalytics from "./components/FlowAnalytics"; // Flow analytics

import "./App.css";

function App() {
  const location = useLocation(); // Hook to get current route

  // Google Analytics setup
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-X7QKSZ0G7R";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-X7QKSZ0G7R');
    `;
    document.head.appendChild(script2);
  }, []);

  // Track page views on route changes
  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-X7QKSZ0G7R", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <UserProvider>
      <div className="flex flex-col min-h-screen bg-cover bg-center">
        <Header />
        <main className="flex-grow transition-opacity duration-300">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project-explorer" element={<ProjectExplorer />} />
            <Route path="/timeline" element={<Timeline />} />
            <Route path="/communities" element={<Communities />} />
            <Route path="/collection" element={<Collection />} />
            {/* Update News route to not require rssFeeds */}
            <Route path="/news" element={<News />} />
            <Route path="/events" element={<Events />} />
            <Route path="/analytics" element={<Analytics />} />

            {/* Pinnacle Routes */}
            <Route
              path="/pinnacle"
              element={<Navigate to="/pinnacle/pin-explorer" />}
            />
            <Route path="/pinnacle/pin-explorer" element={<Pinnacle />} />
            <Route path="/pinnacle/timeline" element={<Pinnacle />} />
            <Route path="/pinnacle/analytics" element={<Pinnacle />} />
            <Route path="/pinnacle/about" element={<Pinnacle />} />
            <Route path="/pinnacle/series/:seriesId" element={<Pinnacle />} />
            <Route path="/pinnacle/sets/:setId" element={<PinnacleSets />} />
            <Route
              path="/pinnacle/editions/:editionId"
              element={<PinnacleEditions />}
            />

            {/* TopShot Routes */}
            <Route
              path="/topshot"
              element={<Navigate to="/topshot/moment-explorer" />}
            />
            <Route path="/topshot/moment-explorer" element={<TopShot />} />
            <Route path="/topshot/communities" element={<TopShot />} />
            <Route path="/topshot/timeline" element={<TopShot />} />
            <Route path="/topshot/gaming" element={<TopShot />} />
            <Route path="/topshot/analytics" element={<TopShot />} />
            <Route path="/topshot/challenges" element={<TopShot />} />
            <Route path="/topshot/series/:seriesId" element={<TopShot />} />
            <Route path="/topshot/sets/:setId" element={<TopShotSets />} />

            {/* AllDay Routes */}
            <Route
              path="/allday"
              element={<Navigate to="/allday/analytics" />}
            />
            <Route path="/allday/analytics" element={<AllDay />} />
            <Route path="/allday/gaming" element={<AllDay />} />
            <Route path="/allday/communities" element={<AllDay />} />

            {/* CryptoKitties Routes */}
            <Route
              path="/cryptokitties"
              element={<Navigate to="/cryptokitties/cryptokitties-explorer" />}
            />
            <Route
              path="/cryptokitties/cryptokitties-explorer"
              element={<CryptoKitties />}
            />
            <Route
              path="/cryptokitties/allthezen"
              element={<CryptoKitties />}
            />
            <Route
              path="/cryptokitties/analytics"
              element={<CryptoKitties />}
            />

            {/* Flow Routes */}
            <Route
              path="/flow"
              element={<Navigate to="/flow/flow-explorer" />}
            />
            <Route path="/flow/flow-explorer" element={<Flow />} />
            <Route path="/flow/timeline" element={<Flow />} />
            <Route path="/flow/analytics" element={<Flow />} />
          </Routes>
        </main>
        <Footer className="mt-auto" />
      </div>
    </UserProvider>
  );
}

export default App;
