import React, { useState, useEffect } from "react";
import allTheZenData from "../data/AllTheZenData.json";

const AllTheZen = () => {
  const [kittyGroups, setKittyGroups] = useState([]);
  const [trophies, setTrophies] = useState([]);
  const [totalTrophies, setTotalTrophies] = useState(0);

  useEffect(() => {
    // Load data from the JSON file
    setKittyGroups(allTheZenData.kittyGroups);
    setTrophies(allTheZenData.trophies);
    setTotalTrophies(allTheZenData.totalTrophies);
  }, []);

  // Helper function to capitalize the first letter of each word
  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
  };

  // Function to generate image URLs by removing spaces and capitalizing each word
  const getTrophyImageUrl = (trophyName) => {
    const formattedName = capitalizeWords(trophyName.replace(/\s+/g, "")); // Remove spaces and capitalize
    return `https://storage.googleapis.com/flowconnect/allthezen/trophies/${formattedName}.png`;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">ALL THE ZEN!: Game Overview</h2>

      {/* Trophies Section */}
      <h3 className="text-xl font-bold mt-6 mb-2">Trophies</h3>
      <p>There are currently {totalTrophies} trophies in the game.</p>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
        {trophies.map((trophy, index) => (
          <div
            key={index}
            className="border rounded-lg p-4 text-center shadow-lg flex flex-col justify-between"
          >
            <img
              src={getTrophyImageUrl(trophy.trophyName)}
              alt={trophy.trophyName}
              className="w-24 h-24 mx-auto mb-4"
              onError={(e) => {
                e.target.src = "https://via.placeholder.com/100";
              }} // Placeholder for missing images
            />
            <div className="flex flex-col items-center">
              <h4
                className="text-lg font-bold mb-1"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical",
                  maxHeight: "2.75rem", // Max height for 2 lines
                  lineHeight: "1.375rem", // Set line height
                }}
              >
                {trophy.trophyName}
              </h4>
              <p
                className="text-sm text-gray-700 mb-2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical",
                  maxHeight: "2.75rem", // Max height for 2 lines
                  lineHeight: "1.375rem", // Set line height
                  fontSize: "0.875rem",
                }}
              >
                {trophy.description}
              </p>
            </div>
            <p
              className="text-sm text-gray-500"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2, // Limit to 2 lines
                WebkitBoxOrient: "vertical",
                maxHeight: "2.75rem", // Max height for 2 lines
                lineHeight: "1.375rem", // Set line height
              }}
            >
              Unlock Rank #1 User:{" "}
              {trophy.unlockRank1User || "Not yet unlocked"}
            </p>
          </div>
        ))}
      </div>

      {/* Kitty Groups Section */}
      <h3 className="text-xl font-bold mt-6 mb-2">Kitty Explorer</h3>
      <p className="mb-4">
        The Kitty Explorer offers a detailed look at different Kitty groups,
        each with unique traits that affect ZEN production. The following chart
        outlines their ZEN stats:
      </p>

      <table className="table-auto w-full mb-4">
        <thead>
          <tr>
            <th className="px-4 py-2">Kitty Group</th>
            <th className="px-4 py-2">Kitty</th>
            <th className="px-4 py-2 text-purple-500">Emanates</th>
            <th className="px-4 py-2 text-yellow-500">Accumulates</th>
            <th className="px-4 py-2 text-lime-500">Pet Bonus</th>
          </tr>
        </thead>
        <tbody>
          {kittyGroups.map(({ groupName, kitties }) =>
            kitties.map((kitty, index) => (
              <tr key={`${groupName}-${kitty.name}`}>
                {index === 0 && (
                  <td className="border px-4 py-2" rowSpan={kitties.length}>
                    {groupName}
                  </td>
                )}
                <td className="border px-4 py-2">{kitty.name}</td>
                <td className="border px-4 py-2 text-purple-500">
                  {kitty.emanates}
                </td>
                <td className="border px-4 py-2 text-yellow-500">
                  {kitty.accumulates}
                </td>
                <td className="border px-4 py-2 text-lime-500">
                  {kitty.petBonus}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllTheZen;
