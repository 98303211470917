import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { FaSpinner } from "react-icons/fa";
import {
  loadEditionImage,
  getSetBackground,
  getEditionTier,
  parseUnicode,
} from "./GenericHelpers";

const fetchDataFromAPI = async (url, errorMessage) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`${errorMessage}: ${response.statusText}`);
  }
  return response.json();
};

const TopShotSets = () => {
  const { setId } = useParams();
  const navigate = useNavigate();
  const [setData, setSetData] = useState(null);
  const [editions, setEditions] = useState([]);
  const [plays, setPlays] = useState([]);
  const [error, setError] = useState(null);
  const { user, nftCollection } = useContext(UserContext);
  const [imageUrls, setImageUrls] = useState({});

  const imgObserver = useRef(null);

  useEffect(() => {
    const fetchSetData = async () => {
      try {
        const setsData = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-sets",
          "Failed to fetch sets data"
        );
        const currentSet = setsData.find((set) => set.id === Number(setId));
        if (!currentSet) throw new Error(`Set with ID ${setId} not found`);

        setSetData(currentSet);

        const editionsRes = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-editions",
          "Failed to fetch editions data"
        );
        const filteredEditions = editionsRes
          .filter((edition) => edition.setID === Number(setId))
          .sort((a, b) => a.playOrder - b.playOrder);

        setEditions(filteredEditions);

        const playsRes = await fetchDataFromAPI(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-plays",
          "Failed to fetch plays data"
        );
        setPlays(playsRes);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSetData();
  }, [setId]);

  useEffect(() => {
    imgObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const imgUrl = img.getAttribute("data-src");
          if (img && imgUrl) {
            img.src = imgUrl;
          }
          imgObserver.current.unobserve(img);
        }
      });
    });

    return () => {
      if (imgObserver.current) {
        imgObserver.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = editions.map((edition) => {
        const editionID = `${edition.setID}_${edition.playID}`;
        const imageUrl = `https://storage.googleapis.com/flowconnect/topshot/images_small/${editionID}.jpg`;

        return Promise.resolve({
          id: edition.playID,
          url: imageUrl,
        });
      });

      const loadedImages = await Promise.all(imagePromises);
      const imageMap = loadedImages.reduce(
        (acc, { id, url }) => ({ ...acc, [id]: url }),
        {}
      );
      setImageUrls(imageMap);
    };

    if (editions.length > 0) {
      loadImages();
    }
  }, [editions]);

  if (error) {
    return <div className="p-8 text-red-500">Error: {error}</div>;
  }

  if (!setData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
        <span className="ml-2 text-xl">Loading...</span>
      </div>
    );
  }

  const renderEditionCard = (edition) => {
    const ownedCount =
      user?.loggedIn && nftCollection?.[edition.playID]
        ? nftCollection[edition.playID].length
        : 0;

    const editionTier = getEditionTier(edition.setID, edition.playID);
    const backgroundColor = getSetBackground("TopShot", editionTier);
    const editionID = `${edition.setID}_${edition.playID}`;
    const play = plays.find((p) => p.PlayID === edition.playID);

    return (
      <div
        key={edition.playID}
        className={`relative border px-4 py-2 rounded-lg shadow-md text-left flex flex-col justify-between transition-transform duration-300 hover:scale-105 cursor-pointer ${backgroundColor} text-slate-200 ${
          ownedCount > 0 ? "border-4 border-green-400" : ""
        }`}
        style={{ minWidth: "260px" }}
      >
        {ownedCount > 0 && (
          <div className="absolute top-2 left-2 bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold z-10">
            {ownedCount}X
          </div>
        )}

        <div className="w-full h-[250px] mb-1 overflow-hidden flex items-center justify-center">
          {/* Image or Placeholder */}
          {imageUrls[edition.playID] ? (
            <img
              data-src={imageUrls[edition.playID]}
              alt={`Play ${edition.playID}`}
              loading="lazy"
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = "none"; // Hide the broken image
              }}
              ref={(img) => {
                if (img && imgObserver.current) {
                  imgObserver.current.observe(img);
                }
              }}
            />
          ) : (
            <div className="w-full h-full bg-black" />
          )}
        </div>

        {/* Reduced gap between the image and title */}
        {play ? (
          <h2 className="text-xl font-bold text-center mt-1">
            {play.FullName}
          </h2>
        ) : (
          <h2 className="text-xl font-bold text-center mt-1">Unknown Player</h2>
        )}

        {/* Play ID */}
        <p className="text-sm mt-1">
          <strong>Play ID:</strong> {edition.playID}
        </p>

        {/* Edition Details */}
        <p className="text-sm mt-1">
          <strong>Retired:</strong> {edition.retired ? "Yes" : "No"}
        </p>
        <p className="text-sm mt-1">
          <strong>Moment Count:</strong> {edition.momentCount}
        </p>
      </div>
    );
  };

  return (
    <div className="p-8">
      {/* Back Button */}
      <button
        className="mb-4 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        onClick={() => navigate(`/topshot/series/${setData.series}`)}
      >
        ← Back
      </button>

      {/* Display the parsed set name */}
      <h1 className="text-3xl font-bold mb-4">{parseUnicode(setData.name)}</h1>

      {/* Display editions for this Set */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 mt-2">
        {editions.map((edition) => renderEditionCard(edition))}
      </div>
    </div>
  );
};

export default TopShotSets;
