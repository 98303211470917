// config.js
import * as fcl from "@onflow/fcl";

let isConfigured = false;

export function getFclInstance() {
  if (!isConfigured) {
    console.log("Configuring FCL");
    fcl.config({
      "flow.network": "mainnet",
      "accessNode.api": "https://rest-mainnet.onflow.org",
      "discovery.wallet": "https://fcl-discovery.onflow.org/authn",
      "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/authn",
      "walletconnect.projectId": "1c74f5c9166063f0f3d2eb0cc6b30265",
      "app.detail.title": "FlowConnect",
      "app.detail.icon": "https://flowconnect.io/logo.png",
      "app.detail.description": "Connecting Flow Communities",
      "app.detail.url": "https://flowconnect.io",
    });
    isConfigured = true;
  }
  return fcl;
}

export default getFclInstance();
