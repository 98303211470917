import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register necessary ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CryptoKittiesEggsRarities = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Static data for Doughnut chart
    const staticData = [
      { RARITY: "Common", TOTAL_MINTED: 2415 },
      { RARITY: "Rare", TOTAL_MINTED: 103 },
      { RARITY: "Uncommon", TOTAL_MINTED: 616 },
    ];

    // Group data by rarity and sum total minted
    const groupedData = staticData.reduce((acc, item) => {
      if (!acc[item.RARITY]) {
        acc[item.RARITY] = 0;
      }
      acc[item.RARITY] += item.TOTAL_MINTED;
      return acc;
    }, {});

    // Explicitly set the order of rarities
    const orderedRarities = ["Common", "Uncommon", "Rare"];
    const totalMinted = orderedRarities.map(
      (rarity) => groupedData[rarity] || 0
    );

    setLoading(false);

    // Set chart data
    setChartData({
      labels: orderedRarities,
      datasets: [
        {
          label: "Percentage of Total Minted",
          data: totalMinted,
          backgroundColor: ["#4BC0C0", "#FFCD56", "#FF6384"],
          hoverBackgroundColor: ["#36A2EB", "#FF9F40", "#FF6384"],
          borderColor: "#fff",
          borderWidth: 2,
        },
      ],
    });
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2 text-center">CK: Egg Rarities</h3>
        {loading ? (
          <p>Loading chart data...</p>
        ) : (
          <div className="flex justify-center items-center">
            <div style={{ width: "350px", height: "350px" }}>
              <Doughnut
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const total = chartData.datasets[0].data.reduce(
                            (acc, curr) => acc + curr,
                            0
                          );
                          const percentage = (
                            (tooltipItem.raw / total) *
                            100
                          ).toFixed(2);
                          return `${tooltipItem.label}: ${percentage}%`;
                        },
                      },
                    },
                    datalabels: {
                      color: "#fff",
                      font: {
                        weight: "bold",
                      },
                      formatter: (value, ctx) => {
                        const total = ctx.chart.data.datasets[0].data.reduce(
                          (acc, curr) => acc + curr,
                          0
                        );
                        const percentage = ((value / total) * 100).toFixed(2);
                        return `${value} (${percentage}%)`;
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CryptoKittiesEggsRarities;
