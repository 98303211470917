import React, { useEffect, useState } from "react";
import FlowLogo from "../logos/flow.svg"; // Path to the Flow logo
import FlowverseLogo from "../logos/flowverse.png"; // Example logos
import EmeraldCityLogo from "../logos/EmeraldCity.png";
import FlowtyLogo from "../logos/flowty.svg";
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom"; // Add navigation
import Timeline from "./Timeline"; // Import Timeline component
import FlowAnalytics from "./FlowAnalytics"; // Import FlowAnalytics component

const Flow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("flow-explorer");

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    setActiveTab(pathSegments[1] || "flow-explorer");
  }, [location]);

  const handleTabChange = (tab) => {
    navigate(`/flow/${tab}`);
  };

  return (
    <div className="p-2">
      {/* Banner with logo and social links */}
      <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-pink-300 via-blue-300 to-teal-300 p-6 rounded-lg shadow-lg">
        <a
          href="https://flow.com"
          target="_blank"
          rel="noopener noreferrer"
          className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
        >
          <img src={FlowLogo} alt="Flow Logo" className="h-20" />
        </a>
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
          <a
            href="https://www.instagram.com/flowblockchain/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaInstagram className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Instagram</span>
          </a>
          <a
            href="https://x.com/flow_blockchain"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaTwitter className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">X</span>
          </a>
          <a
            href="https://discord.gg/flowblockchain"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaDiscord className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Discord</span>
          </a>
          <a
            href="https://www.reddit.com/r/flowblockchain/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaReddit className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Reddit</span>
          </a>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex space-x-4 mt-4 mb-8 border-b-2 pb-2">
        <button
          onClick={() => handleTabChange("flow-explorer")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "flow-explorer"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Flow Explorer
        </button>
        <button
          onClick={() => handleTabChange("timeline")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "timeline"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Timeline
        </button>
        <button
          onClick={() => handleTabChange("analytics")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "analytics"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Analytics
        </button>
      </div>

      {/* Content Based on Active Tab */}
      {activeTab === "flow-explorer" && (
        <div className="space-y-6">
          {/* Summary with Ecosystem, Development, Marketplaces */}
          <div className="bg-gradient-to-r from-blue-300 to-blue-800 p-4 rounded-lg">
            <h2 className="text-xl font-bold text-white mb-2">
              Flow Ecosystem
            </h2>
            <div className="flex items-center space-x-4">
              <img src={FlowverseLogo} alt="Flowverse" className="w-10 h-10" />
              <div>
                <a
                  href="https://www.flowverse.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white underline"
                >
                  Flowverse
                </a>
                <div className="flex space-x-2">
                  <a
                    href="https://discord.com/invite/Krvxfmv8Dy"
                    className="text-white"
                  >
                    <FaDiscord />
                  </a>
                  <a href="https://x.com/flowverse_" className="text-white">
                    <FaTwitter />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-r from-green-600 to-yellow-300 p-4 rounded-lg">
            <h2 className="text-xl font-bold text-white mb-2">Development</h2>
            <div className="flex items-center space-x-4">
              <img
                src={EmeraldCityLogo}
                alt="Emerald City"
                className="w-10 h-10"
              />
              <div>
                <a
                  href="https://docs.ecdao.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white underline"
                >
                  Emerald City
                </a>
                <div className="flex space-x-2">
                  <a href="https://discord.gg/68ZH524d" className="text-white">
                    <FaDiscord />
                  </a>
                  <a href="https://x.com/emerald_dao" className="text-white">
                    <FaTwitter />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-r from-purple-700 to-green-500 p-4 rounded-lg">
            <h2 className="text-xl font-bold text-white mb-2">Marketplaces</h2>
            <div className="flex items-center space-x-4">
              <img src={FlowtyLogo} alt="Flowty" className="w-10 h-10" />
              <div>
                <a
                  href="https://www.flowty.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white underline"
                >
                  Flowty
                </a>
                <div className="flex space-x-2">
                  <a
                    href="https://discord.com/invite/flowty"
                    className="text-white"
                  >
                    <FaDiscord />
                  </a>
                  <a href="https://x.com/flowty_io" className="text-white">
                    <FaTwitter />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "timeline" && <Timeline defaultProject="Flow" />}
      {activeTab === "analytics" && <FlowAnalytics />}
    </div>
  );
};

export default Flow;
